import React from "react";
import "./style1.css";
import { Modal } from "react-bootstrap";
import SLIDER from "../../Carousel/SLIDER";

const Popup = (props) => {
  const { src, open, handleClose } = props;
  const vidRef = React.useRef();

  // useEffect(() => {
  //   const submit = document.getElementById("iframewin");
  //   alert(submit.submit());
  // });

  return (
    <>
      <Modal show={open} onHide={handleClose} centered size="md" id="my-modal">
        {/* <Modal.Header
            className="modalhead1"
            closeButton
            onHide={handleClose}
            id="modalnewe"
          ></Modal.Header> */}
        <Modal.Body>
          <button
            onClick={handleClose}
            className="btn btn-pos text-white bg-danger"
          >
            x
          </button>

          <SLIDER />
          {/* <div className="popup1"> */}
          {/* <div className="contentBox1"> */}
          {/* <video
            className="videoplayer"
            controls
            ref={vidRef}
            muted
            autoplay="autoplay"
            // loop playsInline

            disablePictureInPicture
            src={src}
            type="video/mp4"
            controlslist="nodownload noplaybackrate"
          >
            Your browser does not support the video tag.
          </video> */}

          {/* </div> */}
          {/* </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Popup;
