import { Container, Row, Col } from "reactstrap";

import f1 from "../../../Images/ZohoPng/capture.png";
import f2 from "../../../Images/ZohoPng/engage.png";
import f3 from "../../../Images/ZohoPng/rank.png";
import f4 from "../../../Images/ZohoPng/funnel.png";
import f5 from "../../../Images/ZohoPng/roi.png";

import styles from "./styles.module.css";
const FEATURES = [
  {
    src: f1,
    title: "Capture and Convert Potential Customers",
    content:
      "Maximize lead acquisition with customizable forms and pop-ups, tailored landing pages, and CRM integration for efficient lead management and enhanced visitor engagement.",
  },
  {
    src: f2,
    title: "Engage and Nurture Leads Through Multichannel Automation ",
    content:
      "Nurture leads into loyal customers by guiding them through personalized journeys and executing conversion-focused campaigns. Save time with efficient email automation while utilizing a personalized, data-driven strategy to nurture leads effectively.",
  },
  {
    src: f3,
    title: "Rank Leads According to Their Intent",
    content:
      "Identify and prioritize prospects ready for purchase, evaluating lead quality to accelerate deal closures. Analyze lead distribution across stages and transform them into customers by thoroughly qualifying each opportunity.",
  },
  {
    src: f4,
    title: "Send Exclusively Sales-Ready Leads to the Sales Team",
    content:
      "Simplify lead guidance with intuitive drag-and-drop features and pre-designed templates, ensuring smooth journeys tailored to your needs. Achieve efficiency beyond email automation with instant analytics.",
  },
  {
    src: f5,
    title: "Determine which Campaigns / Events Yield high ROI",
    content:
      "Efficiently oversee marketing efforts, budgeting, and coordination through a centralized platform, fostering collaboration across teams by monitoring ROI and touchpoint analytics.",
  },
];

export default function OurFeatures() {
  return (
    <Container id="ourfeatures" fluid className={styles.ourFeatures}>
      <h1 className={styles.heading}>Our Features</h1>
      <Row className="justify-content-center px-lg-0 px-2">
        {FEATURES.map((elem, index) => {
          return (
            <Col lg="3" className={styles.ourFeaturesCol}>
              <span className={styles.featureNum}>{index + 1}</span>
              <img src={elem.src} alt={elem.title} />
              <h1>{elem.title}</h1>
              <p>{elem.content}</p>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
