import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Assets from "../../Config/Assets";
import { Swiper } from "swiper/react";
import Aos from "aos";
import "aos/dist/aos.css";

import { Card, Row, Col, Container } from "reactstrap";

import AboutUs_Video from "../../Images/TTIPL.mp4";

const About_us = () => {
  const vidRef = React.useRef();
  const [counter, setCounter] = React.useState(0);
  const [percentage, setPercentage] = React.useState(0);
  const [partners, setPartners] = React.useState(0);
  const [annuval, setAnnuval] = React.useState(0);

  useEffect(() => {
    let interval;
    let interval1;
    let interval2;

    let interval3;

    if (percentage < 30) {
      interval1 = setInterval(() => {
        setPercentage((percentage) => percentage + 1);
      }, 300);
    }
    if (partners < 40) {
      interval2 = setInterval(() => {
        setPartners((partners) => partners + 1);
      }, 300);
    }
    if (counter < 20) {
      interval = setInterval(() => {
        setCounter((counter) => counter + 1);
      }, 300);
    }
    if (annuval < 74) {
      interval3 = setInterval(() => {
        setAnnuval((annuval) => annuval + 1);
      }, 300);
    }
    return () => {
      clearInterval(interval);
      clearInterval(interval1);
      clearInterval(interval2);
      clearInterval(interval3);
    };
  }, [counter, percentage, partners, annuval]);

  useEffect(() => {
    vidRef.current.play();
  }, []);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <>
      <div className="container" id={styles.AboutMainheader}>
        <div className="row">
          <div className="col-lg-6">
            <div className={styles.whoweareheader}>
              <p>Why TTIPL ?</p>
              <span>
                As a strong partner for accelerating your growth, we help
                businesses maximize sustainable value with our software
                engineering services spanning the supply chain, healthcare,
                FinTech, IoT, and other domains. We share the valuable and
                wide-ranging knowledge of our talented developers in order to
                design and deliver world-class products and sessions.
              </span>
            </div>
          </div>
          <div class="col-lg-6">
            <video
              width="100%"
              height="100%"
              className={styles.Videoplayer}
              controls
              ref={vidRef}
              muted
              style={{ margin: "auto" }}
              disablePictureInPicture
              src={AboutUs_Video}
              type="video/mp4"
              controlslist="nodownload noplaybackrate"
            ></video>
          </div>
        </div>
      </div>
      <div className={styles.PartnersHeader}>
        <p>Partners and clients</p>
        <div className="container">
          <div className="row" style={{ padding: "2rem 0 0 0" }}>
            <div class="col-lg-3">
              <span className={styles.PartnersCounter}>{annuval}%</span>
              <h6>Annual company growth</h6>
            </div>
            <div class="col-lg-3">
              <span className={styles.PartnersCounter}>{percentage}%</span>
              <h6>
                Of clients come to Talent Takeaways via a partner reference
              </h6>
            </div>
            <div className="col-lg-3">
              <span className={styles.PartnersCounter}>{counter}+</span>
              <h6>Clients with active projects</h6>
            </div>

            <div class="col-lg-3">
              <span className={styles.PartnersCounter}>{partners}+</span>
              <h6>
                Partners that have been collaborating with Talent Takeaways for
                8+ years
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div className="container" id={styles.AboutMainheader}>
        <div class="row">
          <div class="col-lg-12">
            <div className={styles.whoweareheader}>
              <p>Management Team</p>
              <span>
                Talent Takeaways has a reputation as an egalitarian outsourcing
                company, and our management board members stay in constant touch
                with TTIPL employees and clients. Our clients rest assured that
                every project and every opportunity will receive the necessary
                level of strategic supervision from our able leadership team.
              </span>
            </div>

            <Container>
              <Swiper
                spaceBetween={50}
                slidesPerView={2}
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 11,
                  },
                  640: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                }}
              ></Swiper>
            </Container>
          </div>
        </div>
        <Container className={styles.MangementTeamHeader1}>
          <Row>
            <Col lg="6">
              <div className={styles.card1}>
                <Card className={styles.CardImage}>
                  <img alt="Sample" src={Assets.Images.KumarSir} />
                  <div className={styles.CardText}>
                    <div className={styles.linktinHeder}>
                      <span>Kumar Srinivasan</span>
                    </div>
                    <p className={styles.position}>DIRECTOR of TTIPL</p>
                  </div>
                </Card>
              </div>
            </Col>
            <Col lg="6">
              <div className={styles.card}>
                <Card className={styles.CardImage}>
                  <img alt="Sample" src={Assets.Images.Elangovansir} />
                  <div className={styles.linktinHeder}>
                    <span>Elangovan Balasubramanian </span>
                  </div>
                  <p className={styles.position}>DIRECTOR of TTIPL</p>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div style={{ background: "#ffffff" }}>
        <div className="container" id={styles.AboutMainheader}>
          <div class="row">
            <div class="col-lg-6 ">
              <div className={styles.Peopleexcellence}>
                <p>Resource Development</p>
                <span>
                  We constantly improve our company’s performance by focusing on
                  the continuous learning and development of our talented
                  resources.
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <div className={styles.peopleexcellence}>
                <h6 data-aos="fade-down" className={styles.Individual}>
                  Individual development plans and clear career paths.
                </h6>
                <h6 className={styles.Individual}>
                  Mentorship and support from experienced colleagues.
                </h6>
              </div>
              <div className={styles.peopleexcellence}>
                <h6 className={styles.Individual}>
                  Regular appraisals and increments based on performance
                  results.
                </h6>
                <h6 data-aos="fade-up" className={styles.Individual}>
                  Training at TTIPL and participation in professional
                  conferences.
                </h6>
              </div>
            </div>
          </div>
          {/* <div className={styles.Benefits}>
        <p>Benefits </p><span>of working with</span> 

       </div> */}
        </div>
      </div>
      <Container className={styles.offer}>
        <div className={styles.Benefits}>
          <p className={styles.text}>
            <span className={styles.dedicated}>
              Dedicated <br />
              Industry Oriented Services
              {/* of working with <br></br>an industry-dedicated team */}
            </span>
          </p>
        </div>
        <Row>
          <Col lg="6">
            <div className={styles.BenefitsCard}>
              <img src={Assets.Images.ICON} alt="" />
              <p>Access Our Knowledge</p>
              <span>
                Experience in creating solutions for specific industries allows
                us to understand important requirements, offer flexible and
                scalable architecture, and supply reliable alternatives for each
                functional module. We can deliver a relevant technical solution
                to support your business continuity and successful operations.
              </span>
            </div>
          </Col>
          <Col lg="6">
            <div className={styles.BenefitsCard}>
              <img src={Assets.Images.ICON} alt="" />
              <p>Effortlessly Scale-up your Business</p>
              <span>
                Aside from a pool of industry-focused experts, TTIPL has a
                well-established framework for training and developing new team
                members. With workshops and regular knowledge-sharing sessions,
                we ensure our employees obtain the domain-specific expertise
                needed to create top-notch custom solutions.
              </span>
            </div>
          </Col>
        </Row>
      </Container>
      <Container style={{ paddingTop: "1.5rem", paddingBottom: "2rem" }}>
        <Row>
          <Col lg="6">
            <div className={styles.BenefitsCard}>
              <img src={Assets.Images.ICON} alt="" />
              <p>Optimize Development</p>
              <span>
                Enjoy a smooth and safe onboarding process through our efficient
                and experienced team. We can help you form your product faster
                and start developing earlier than a team with less domain
                expertise.
              </span>
            </div>
          </Col>

          <Col lg="6">
            <div className={styles.BenefitsCard}>
              <img src={Assets.Images.ICON} alt="" />
              <p>Increase the Value of Your Company</p>
              <span>
                With TTIPL, you receive more than a team of professional
                developers. You also get our expertise, the knowledge of subject
                matter experts we consult, and our experience creating
                industry-specific custom software products and optimising
                business processes for improved efficiency, scalability, and
                customer satisfaction.
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default About_us;
