const Mainblog = {
  Cards: [
    {
      id: 1,
      color: "#2c3e50",
      image: require("../Images/img1.png"),
      content: "Using Machine Learning To Code Occupational Surveillance Data",
      date: "January 19, 2023",
      readtime: "Read Time: 5mins",
      blogdata: {
        insideimage: require("../Images/img1inside.png"),
        title: "Using Machine Learning To Code Occupational Surveillance Data",
        url: "Visit Youtube",
        goback: "BACK TO BLOGS",
        mainContent: [
          {
            cardId: 1,
            heading: "What Is Machine Learning?",
            contentPara: [
              "Machine learning (ML) is a complex but exciting field. It helps in analysing a large amount of data using specific algorithms to arrive at data-driven decisions by predicting patterns and trends. This technology has many potential applications, such as image recognition, speech recognition, traffic pattern prediction, e-commerce product recommendations, virtual personal assistants, and internet search engines. It is also widely used in e-mail filters to sort out spam, websites to make personalised recommendations, banking software to detect unusual transactions, etc. It overlaps with AI as well as robotics.",
            ],
          },
          {
            heading: "Classification of Machine Learning:",
            contentPara: [
              "ML is a concept that poses a lot of potential as well as challenges and needs knowledge of programming to be transformed into workable proposals. To talk to the machines, certain basic computer skills in programming are a must. At the same time, good knowledge in statistics, deep learning, systems processing, and design will be an added advantage.",
            ],
          },
          {
            heading: "Features of Machine Learning:",
            contentPara: [
              "Lower-level programming languages such as R, C++, and Java are used for high-speed requirements, while higher-level languages such as Python and Java Script are used for slower applications. However, for beginners, Python is easier to learn and use. The truth is that there is no one ‘correct’ language to learn for machine learning. Each language has its own traits, and some are more popular than others.",
            ],
          },
          {
            heading: "Machine Learning At present",

            contentPara: [
              "Python is syntactically straightforward and easier to use and is therefore considered a key language for machine learning applications. R is popular with statisticians as it allows users to apply specialised statistical techniques. C++ is popular because it communicates with machines close to their native code and is also excellent for memory manipulation due to its speed. Java is highly scalable and is used to create complex, large-scale algorithms. JavaScript is another high-level language, and it is often used as an API for apps built using languages that may lack JavaScript’s high-level mathematical functionality.",

              "If you wish to have a comprehensive knowledge of what Machine learning is all about, we recommend you to undergo the basic course offered by NPTEL – IIT Madras, in the following link",
            ],
          },
        ],
        comment: [
          {
            commentId: 1,
            comment: "",
            userName: "",
            userEmail: "",
            createdAt: "",
            isLiked: false,
            likeCount: 1,
            reply: [
              {
                replyId: 1,
                commentId: 1,
                comment: "",
                userName: "",
                userEmail: "",
                createdAt: "",
                isLiked: false,
                likeCount: 1,
                reply: [],
              },
            ],
          },
        ],
      },
    },
    {
      id: 2,
      color: "#1c6443",
      image: require("../Images/img2.jpeg"),
      content: "Network Security Importance of disaster recovery planning",
      date: "January 23, 2023",
      readtime: "Read Time: 5mins",
      blogdata: {
        insideimage: require("../Images/img5inside.png"),
        title: "Network Security/ Importance of disaster recovery planning.",
        goback: "BACK TO BLOGS",
        mainContent: [
          {
            cardId: 2,
            heading: "What is disaster recovery in network security?",
            contentPara: [
              "Disaster recovery refers to an organised method of restoring access and functionality to the IT infrastructure after unexpected disruptions in business. A disaster can be related to any incident (both intentional and/or non-intentional) that renders severe damage to the data storage of the company. This includes things such as human error, power failure, equipment failure, virus intrusion, general server crashes, theft of devices, sabotage, and cyber-attacks.",
              "Network security protects our network and data from breaches, intrusions, and other threats. This is a vast and overarching term that describes hardware and software solutions as well as processes, rules, and configurations relating to network use, accessibility, and overall threat protection. The three phases of a disaster programme are disaster planning, disaster management, and disaster recovery.",
            ],
          },
          {
            heading: "What is a disaster recovery plan?",
            contentPara: [
              "To reduce the risk and damage of such an unfortunate event, organisations prefer to have a preventive action plan coupled with a quick recovery back to normal. The basic structure for disaster planning includes a formal document for prevention, preparedness, response, and recovery in the event of a catastrophe. Such a plan should undergo thorough testing to ensure its success when needed. The plan should be easy to follow and understand and be customised to meet the unique needs of the organization.",
            ],
          },
          {
            heading:
              "What are the typical elements of a disaster recovery plan (DRP)?",
            contentPara: [
              "Lower-level programming languages such as R, C++, and Java are used for high-speed requirements, while higher-level languages such as Python and Java Script are used for slower applications. However, for beginners, Python is easier to learn and use. The truth is that there is no one ‘correct’ language to learn for machine learning. Each language has its own traits, and there are some languages that are more popular than others.",
              "The first activity could be to put a recovery team in place, defining the responsibilities of each team member. It should identify the go-to person, provide contact numbers, and keep all parties concerned fully informed of the action plan in case of a disaster.",
              "The team should then assess the risks specific to the organisation and also prepare a recovery strategy by identifying the critical business processes needed for smooth operations. It should focus on short-term, quick solutions for survival until long-term plans are put into use.",
              "The team should identify what to back up, by whom, how to perform the backup, the location of the backup, and how frequently backups should occur. All critical applications, equipment, and documents should be backed up and stored in a safe off-site location. The recovery team should regularly update the DRP to accommodate for changes in business processes, technology, and evolving disaster risks.",
            ],
          },
          {
            heading: "Summary",
            contentPara: [
              "The organisation should ensure sturdy devices, the deletion of old backups that are not required any longer, a proper safe location for keeping the backups, and regular backups as per plan. The recovery team and organisation must then implement the DRP and follow through on the plan's procedures. The DRP should be continually tested and maintained to consistently prepare the organisation for evolving disasters and emergencies.",
            ],
          },
        ],
        comment: [
          {
            commentId: 1,
            comment: "",
            userName: "",
            userEmail: "",
            createdAt: "",
            isLiked: false,
            likeCount: 1,
            reply: [
              {
                replyId: 1,
                commentId: 1,
                comment: "",
                userName: "",
                userEmail: "",
                createdAt: "",
                isLiked: false,
                likeCount: 1,
                reply: [],
              },
            ],
          },
        ],
      },
    },
    {
      id: 3,
      color: "#ac5119",
      image: require("../Images/img3.jpeg"),
      content: "What is Chat-GPT(Chat Generative Pre-trained Transformer) ?",
      date: "March 2, 2023",
      readtime: "Read Time: 5mins",
      blogdata: {
        insideimage: require("../Images/img3inside.png"),
        title: "What is Chat-GPT(Chat Generative Pre-trained Transformer) ?",
        goback: "BACK TO BLOGS",
        mainContent: [
          {
            cardId: 2,
            heading: "Natural language processing:",
            contentPara: [
              "Chat-GPT uses natural language processing techniques to understand and generate human-like responses to user input. It can understand and interpret a wide range of natural language inputs, including slang, idioms, and colloquialisms.",
            ],
          },
          {
            heading: "Contextual understanding:",
            contentPara: [
              "Chat-GPT is capable of understanding and maintaining context throughout a conversation. It can take previous user inputs and responses into account to provide more accurate and relevant responses.",
            ],
          },
          {
            heading: "Personalization:",
            contentPara: [
              "Chat-GPT can be fine-tuned based on specific data sets to provide personalised responses tailored to specific users or industries. This allows businesses and organisations to create more engaging and personalised customer experiences.",
            ],
          },
          {
            heading: "Multilingual support:",
            contentPara: [
              "Chat-GPT supports multiple languages, allowing it to be used in a wide range of global applications.",
            ],
          },
          {
            heading: "Flexibility:",
            contentPara: [
              "Chat-GPT can be easily integrated into a variety of chatbot and virtual assistant platforms, making it a versatile tool for businesses and organizations looking to improve their online presence and customer service.",
            ],
          },
          {
            heading: "Human-like responses:",
            contentPara: [
              "The organisation should ensure sturdy devices, the deletion of old backups that are not required any longer, a proper safe location for keeping the backups, and regular backups as per plan. The recovery team and organisation must then implement the DRP and follow through on the plan's procedures. The DRP should be continually tested and maintained to consistently prepare the organisation for evolving disasters and emergencies.",
            ],
          },
        ],
        comment: [
          {
            commentId: 1,
            comment: "",
            userName: "",
            userEmail: "",
            createdAt: "",
            isLiked: false,
            likeCount: 1,
            reply: [
              {
                replyId: 1,
                commentId: 1,
                comment: "",
                userName: "",
                userEmail: "",
                createdAt: "",
                isLiked: false,
                likeCount: 1,
                reply: [],
              },
            ],
          },
        ],
      },
    },
    {
      id: 4,
      color: "rgb(125 11 68)",
      image: require("../Images/img4.jpeg"),
      content: "UI/UX Design Careers: Scope and Future career opportunities",
      date: "March 6, 2023",
      readtime: "Read Time: 5mins",
      blogdata: {
        insideimage: require("../Images/img6inside.png"),
        title: "UI/UX Design Careers: Scope and Future career opportunities",
        goback: "BACK TO BLOGS",
        mainContent: [
          {
            cardId: 1,
            heading: "UI/UX Designer:",
            contentPara: [
              "This is the most common job title in the field of UI/UX design. UI/UX designers are responsible for designing user interfaces for digital products, including websites, mobile apps, and software applications. They work closely with product managers, developers, and other stakeholders to create user-centered designs that meet business goals and user needs.",
            ],
          },
          {
            heading: "UX Researcher:",
            contentPara: [
              " UX researchers conduct user research to gather insights about user behavior, needs, and preferences. They use a variety of research methods, including surveys, interviews, usability testing, and analytics, to inform design decisions and improve the user experience.",
            ],
          },
          {
            heading: "Information Architect:",
            contentPara: [
              "Information architects organise and structure digital content in a way that is easy to understand and navigate. They create site maps, wireframes, and other visual representations of the information architecture to communicate their design decisions to stakeholders.",
            ],
          },
          {
            heading: "Interaction Designer:",

            contentPara: [
              "Interaction designers focus on the interaction between users and digital products. They design user flows, wireframes, and prototypes to create intuitive and engaging user experiences.",
            ],
          },
          {
            heading: "UI Developer:",

            contentPara: [
              "UI developers work closely with UI/UX designers to implement designs in code. They are responsible for creating responsive, accessible, and visually appealing user interfaces that meet design specifications.",
            ],
          },
        ],
        comment: [
          {
            commentId: 1,
            comment: "",
            userName: "",
            userEmail: "",
            createdAt: "",
            isLiked: false,
            likeCount: 1,
            reply: [
              {
                replyId: 1,
                commentId: 1,
                comment: "",
                userName: "",
                userEmail: "",
                createdAt: "",
                isLiked: false,
                likeCount: 1,
                reply: [],
              },
            ],
          },
        ],
      },
    },
  ],
};

export default Mainblog;
