import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

import styles from "./styles.module.css";
import Comments from "../Comments/Comments";

import { useParams } from "react-router-dom";
import { useState } from "react";
import { FaAngleDoubleRight, FaAngleDoubleLeft } from "react-icons/fa";

import axios from "axios";

const Cardcontent1 = (props) => {
  const [commentDetail, setCommentDetail] = useState({
    status: "",
    id: "",
  });
  const image = [
    {
      id: 1,
      card: 1,
      insideimg: require("../../Images/img1inside.png"),
    },
    {
      id: 2,
      card: 2,
      insideimg: require("../../Images/img5inside.png"),
    },
    {
      id: 3,
      card: 3,
      insideimg: require("../../Images/img3inside.png"),
    },
    {
      id: 4,
      card: 4,
      insideimg: require("../../Images/img6inside.png"),
    },
  ];

  const [data12, setdata12] = useState({});

  const getAllBlogList = () => {
    return fetch("https://fia-uganda-edrms.com:3500/api/bloglist")
      .then((response) => response.json())
      .then((Blogdata) => setItems(Blogdata.data?.result?.Cards))
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllBlogList();
    console.log(getAllBlogList, "getAllBlogList");
  }, []);

  const addCommentToBlog = (blogId, name, email, message) => {
    fetch("https://fia-uganda-edrms.com:3500/api/addComment", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        blogId,
        name,
        email,
        message,
      }),
    }).then((response) => {
      response.json();
      console.log("response", response);
      if (response?.status === 200) {
        alert("your blog comment added successfully");
        getAllBlogList();
        resetForm();
      }
    });
  };

  const [items, setItems] = useState([]);

  useEffect(() => {
    items.map((data) => {
      if (data.id == id) {
        setdata12(data);
      }
    });
  }, [items]);

  const [form, setForm] = useState({
    comment: "",
    userName: "",
    userEmail: "",
    createdAt: "",
    isLiked: false,
    likeCount: 0,
    reply: 0,
  });

  const addReplyToBlog = (commentId, replyId = "0", name, email, message) => {
    fetch("https://fia-uganda-edrms.com:3500/api/addReply", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        commentId,
        replyId,
        name,
        email,
        message,
      }),
    }).then((response) => {
      response.json();
      console.log("response", response);
      if (response?.status === 200) {
        alert("your blog Reply added successfully");
        getAllBlogList();
        resetForm();
      }
    });
  };

  const [likedata, setLikeData] = useState(1);

  const addLikeCommentToBlog = () => {
    const data = { commentId: likedata };
    axios
      .post("https://fia-uganda-edrms.com:3500/api/LikeComment", data)
      .then((response) => {
        console.log("Status: ", response);
        if (response.data?.statusCode === 200) {
          alert("Your Message Like sent Successfully.");
          setLikeData(!likedata + 1);
        }
      })
      .catch((error) => {
        console.error("Something went wrong!", error);
      });
  };

  const regexUrl = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;

  const [blogForm, setBlogForm] = useState({
    comment: "",
    userName: "",
    userEmail: "",
  });

  const [formModal, setFormModal] = useState(false);

  const { id } = useParams();

  // Form on changes
  const onCommentChanges = (event) => {
    setForm({
      ...form,
      comment: event.target.value,
    });
  };

  const onUserNameChange = (event) => {
    setForm({
      ...form,
      userName: event.target.value,
    });
  };

  const onUserEmailChange = (event) => {
    setForm({
      ...form,
      userEmail: event.target.value,
    });
  };

  const onBlogCommentChanges = (event) => {
    setBlogForm({
      ...blogForm,
      comment: event.target.value,
    });
  };

  const onBlogUserNameChange = (event) => {
    setBlogForm({
      ...blogForm,
      userName: event.target.value,
    });
  };

  const onBlogUserEmailChange = (event) => {
    setBlogForm({
      ...blogForm,
      userEmail: event.target.value,
    });
  };

  const onHandleCommentDetail = (status, id) => {
    setCommentDetail({
      status: status,
      id: id,
    });
  };

  const handleFormModalOpen = () => {
    setFormModal(true);
  };

  const handleFormModalClose = () => {
    setFormModal(false);
    setBlogForm({
      comment: "",
      userName: "",
      userEmail: "",
    });
    setForm({
      comment: "",
      userName: "",
      userEmail: "",
      createdAt: "",
      isLiked: false,
      likeCount: 0,
      reply: [],
    });
    setCommentDetail({
      status: "",
      id: "",
    });
  };

  // On resetting the form data

  const resetForm = () => {
    setForm({
      comment: "",
      userName: "",
      userEmail: "",
      createdAt: "",
      isLiked: false,
      likeCount: 0,
      reply: [],
    });
    setCommentDetail({
      status: "",
      id: "",
    });
    setBlogForm({
      comment: "",
      userName: "",
      userEmail: "",
    });
    handleFormModalClose();
  };

  const validation = () => {
    if (blogForm.userEmail === "") {
      alert("Please Enter Your EmailID");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(blogForm.userEmail)
    ) {
      alert("Please Enter Your valid EmailID");
    } else if (blogForm.userName === "") {
      alert("Please Enter Your Name");
    } else if (
      !/(^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z]$)/i.test(blogForm.userName)
    ) {
      alert("Please Enter Your valid Name");
    } else {
      onSubmitForm();
    }
  };

  const onFormSubmit = () => {
    if (form.comment === "") {
      alert("Comment cannot be empty!");
    } else {
      handleFormModalOpen(true);
    }
  };

  // On summiting the modal
  const onSubmitForm = (
    commentId,
    level1Id,
    level2Id,
    level3Id,
    level4Id,
    level5Id,
    level6Id,
    level7Id,
    level8Id,
    level9Id
  ) => {
    if (commentDetail.status === "blogComment") {
      addCommentToBlog(
        id,
        blogForm.userName,
        blogForm.userEmail,
        blogForm.comment
      );
    } else if (commentDetail.status === "level1Reply") {
      if (form.userEmail === "") {
        alert("Please Enter Your EmailID");
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.userEmail)
      ) {
        alert("Please Enter Your valid EmailID");
      } else if (form.userName === "") {
        alert("Please Enter Your Name");
      } else if (!/(^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z]$)/i.test(form.userName)) {
        alert("Please Enter Your valid Name");
      } else {
        addReplyToBlog(
          commentId,
          form.reply[0],
          form.userName,
          form.userEmail,
          form.comment
        );
      }
    } else if (commentDetail.status === "level2Reply") {
      addReplyToBlog(
        commentId,
        form.reply[1],
        form.userName,
        form.userEmail,
        form.comment
      );
    } else if (commentDetail.status === "level3Reply") {
      addReplyToBlog(
        commentId,
        form.reply[2],
        form.userName,
        form.userEmail,
        form.comment
      );
    } else if (commentDetail.status === "level4Reply") {
      addReplyToBlog(
        commentId,
        form.reply[3],
        form.userName,
        form.userEmail,
        form.comment
      );
    } else if (commentDetail.status === "level5Reply") {
      addReplyToBlog(
        commentId,
        form.reply[4],
        form.userName,
        form.userEmail,
        form.comment
      );
    } else if (commentDetail.status === "level6Reply") {
      addReplyToBlog(
        commentId,
        form.reply[5],
        form.userName,
        form.userEmail,
        form.comment
      );
    } else if (commentDetail.status === "level7Reply") {
      addReplyToBlog(
        commentId,
        form.reply[6],
        form.userName,
        form.userEmail,
        form.comment
      );
    } else if (commentDetail.status === "level8Reply") {
      addReplyToBlog(
        commentId,
        form.reply[7],
        form.userName,
        form.userEmail,
        form.comment
      );
    } else if (commentDetail.status === "level9Reply") {
      addReplyToBlog(
        commentId,
        form.reply[8],
        form.userName,
        form.userEmail,
        form.comment
      );
    } else if (commentDetail.status === "level10Reply") {
      addReplyToBlog(
        commentId,
        form.reply[9],
        form.userName,
        form.userEmail,
        form.comment
      );
    }
  };

  const onHandleReply = (id, levelStatus) => {
    if (levelStatus === "level11Reply") {
      alert("Cannot reply");
    } else {
      onHandleCommentDetail(levelStatus, id);
    }
  };

  const onHandleClose = () => {
    onHandleCommentDetail("", -1);
    resetForm();
  };

  const Previousopacity = () => {
    const index = items
      .map((data, index) => {
        if (data.id === id) {
          return index;
        }
        return false;
      })
      ?.filter((fdata) => fdata !== false)[0];
    if (index > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handlePreviousClick = () => {
    const index = items
      .map((data, index) => {
        if (data.id === id) {
          return index;
        }
        return false;
      })
      ?.filter((fdata) => fdata !== false)[0];
    if (index === 0) {
    }
    if (index > 0) {
      window.location.href = `/blog/${items[index - 1].content.replace(
        regexUrl,
        "-"
      )}/${items[index - 1].id}`;
      return false;
    } else {
      return true;
    }
  };

  const nextopacity = () => {
    const index = items
      .map((data, index) => {
        if (data.id == id) {
          return index;
        }
        return false;
      })
      ?.filter((fdata) => fdata !== false)[0];
    if (index < items.length - 1) {
      return true;
    } else {
      return false;
    }
  };

  const handleNextClick = () => {
    const index = items
      .map((data, index) => {
        if (data.id === id) {
          return index;
        }
        return false;
      })
      ?.filter((fdata) => fdata !== false)[0];

    if (index < items.length - 1) {
      window.location.href = `/blog/${items[index + 1].content.replace(
        regexUrl,
        "-"
      )}/${items[index + 1].id}`;
      return false;
    } else {
      return true;
    }
  };
  const [nextStatus, setNextStatus] = useState(false);

  const [backStatus, setBackStatus] = useState(false);

  useEffect(() => {
    const checkNext = nextopacity();
    const checkBack = Previousopacity();

    if (checkNext) {
      setNextStatus(true);
    } else {
      setNextStatus(false);
    }
    if (checkBack) {
      setBackStatus(true);
    } else {
      setBackStatus(false);
    }
  }, [items]);
  return (
    <div>
      <div className={styles.backgroung}>
        <div className={styles.LandingPageContent}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h6>{data12?.blogdata?.title}</h6>
              </div>
              <div className="col-lg-6">
                <div className={styles.action}>
                  <div
                    style={{
                      opacity: !backStatus ? "0.5" : "1",
                    }}
                    className={styles.actionleft}
                    onClick={handlePreviousClick}
                  >
                    {console.log(backStatus, "backStatus")}
                    <FaAngleDoubleLeft />
                    <button onClick={handlePreviousClick}>Previous</button>
                  </div>
                  <div
                    style={{
                      opacity: !nextStatus ? "0.5" : "1",
                    }}
                    className={styles.actionright}
                    onClick={handleNextClick}
                  >
                    <button onClick={handleNextClick}>Next</button>
                    <FaAngleDoubleRight />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.type}>
        <div className={styles.blog1}>
          <Container>
            <div className={styles.bannerImg}>
              <div>
                <img src={image[data12?.id - 1]?.insideimg} alt="" />
              </div>
            </div>
          </Container>
        </div>
        <Container>
          <div className={styles.letter}>
            <div className={styles.main}>
              {data12?.mainContent?.map((datas, i) => {
                return (
                  <div key={i}>
                    <h3>{datas.heading}</h3>
                    <br />
                    {datas.contentPara.map((para, i) => {
                      return <p key={i}>{para}</p>;
                    })}
                  </div>
                );
              })}
              <a
                className={styles.link}
                href="https://www.youtube.com/playlist?list=PL1xHD4vteKYVpaIiy295pg6_SY5qznc77"
              >
                {data12?.blogdata?.url}
              </a>
              <p>&zwj;</p>

              <div className={styles.goback}>
                <a href="/blog" className={styles.backimg}>
                  <div className="d-flex">
                    <img
                      src="https://cdn.onlinewebfonts.com/svg/img_216715.png"
                      className={styles.image_t}
                      alt="back"
                    />
                    <p class="text-block-24">BACK TO BLOG</p>
                  </div>
                </a>
              </div>
              <Comments
                onCommentChanges={onCommentChanges}
                onUserNameChange={onUserNameChange}
                onUserEmailChange={onUserEmailChange}
                form={form}
                onHandleCommentDetail={onHandleCommentDetail}
                formModal={formModal}
                handleFormModalOpen={handleFormModalOpen}
                handleFormModalClose={handleFormModalClose}
                onSubmitForm={onSubmitForm}
                allComments={data12?.comment}
                commentDetail={commentDetail}
                onHandleReply={onHandleReply}
                // onHandleDelete={onHandleDelete}
                onHandleClose={onHandleClose}
                blogForm={blogForm}
                onBlogUserEmailChange={onBlogUserEmailChange}
                onBlogUserNameChange={onBlogUserNameChange}
                onBlogCommentChanges={onBlogCommentChanges}
                validation={validation}
                onFormSubmit={onFormSubmit}
                addLikeCommentToBlog={addLikeCommentToBlog}
              />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
export default Cardcontent1;
