import { Card } from "reactstrap";
import styles from "./styles.module.css";
export default function SolutionCard({ src, heading, content }) {
  return (
    <Card className={styles.solutioncard}>
      <div className={styles.absoluteImage}>
        <img src={src} alt={heading} width="80px" />
      </div>
      <h4 className={styles.header}>{heading}</h4>
      <p className={styles.content}>{content}</p>
    </Card>
  );
}
