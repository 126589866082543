import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import MainHeader from "../Screens/landingPage";
import Footer from "../Screens/landingPage/Footer";

const LayOut = (props) => {
  const {
    scrollChange,
    develop,
    services,
    Contact,
    navbarscrollMain,
    howwedo,
    ourClients,
    Modal,
  } = props;

  const { pathname, key, hash } = useLocation();

  useEffect(() => {
    const path = window.location.href;
    if (path.indexOf("/#") !== -1) {
      console.log("PathNameList");
      const words = path.split("/#");
      document
        .querySelector(`#${words[1]}`)
        .scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [pathname, key, hash]);

  return (
    <>
      <MainHeader
        scrollChange={scrollChange}
        services={services}
        develop={develop}
        Contact={Contact}
        navbarscrollMain={navbarscrollMain}
        howwedo={howwedo}
        ourClients={ourClients}
        Modal={Modal}
      />

      <main>
        <Outlet />
      </main>

      <Footer
        develop={develop}
        services={services}
        howwedo={howwedo}
        ourClients={ourClients}
        scrollChange={scrollChange}
      />
    </>
  );
};
export default LayOut;
