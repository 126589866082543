import React from "react";
import DigitalConsultingServices from "./DigitalConsultingServices";

const Digital=(props)=>{
  const {howwedo}=props;
  return (
    <div style={{ textAlign: "center" }}>
  
    <DigitalConsultingServices howwedo={howwedo}/>
    </div>
  );
}
export default Digital;
