import { Card } from "react-bootstrap";
import styles from "./styles.module.css";
export default function Mission({ src, heading, content }) {
  return (
    <Card className={styles.missionCard}>
      <div className={styles.missionImage}>
        <img src={src} alt={heading} width="30%" />
      </div>
      <h3 className="h2">{heading}</h3>
      <div className={styles.animationCard}>
        <h2 className="h2">{heading}</h2>
        <h5 className={styles.missionContent}>{content}</h5>
      </div>
    </Card>
  );
}
