import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Global from "../../Images/GlobeNetwork.png";
import Personalgrowth from "../../Images/PersonalGrowth.png";
import Mindmap from "../../Images/MindMap.png";
import { Container, Row, Col } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
const SoftwareDevlopment = (props) => {
  const { services } = props;

  return (
    <div className={styles.SoftwareDevlopmentMain} ref={services}>
      <div className={styles.SoftwareDevlopmentMainHeader}>
        <span>Software Development Services</span>
        <div className={styles.SoftwareDevlopmentMainhove}></div>
        <h4 className={styles.SoftwareDevlopmentContent}>
          We offer life cycle support to stretch budgets and seize
          opportunities.
        </h4>
      </div>
      <Container>
        <Row>
          <Col className={styles.PersonalgrowthHeader}>
            <img src={Personalgrowth} alt="personal" data-aos="zoom-in"   data-aos-duration="1000"/>
            <span>Hire from our talent pool of best resources.</span>
          </Col>
          <Col className={styles.PersonalgrowthHeader}>
            <img src={Global} alt="mindmap" data-aos="zoom-in"   data-aos-duration="1000"/>
            <span>Leverage from our experienced technology specialists.</span>
          </Col>
          <Col className={styles.GlobalHeader1}>
            <img src={Mindmap} alt="mindmap" data-aos="zoom-in"  data-aos-duration="1000" />
            <span>
              Experience how TTIPL ramp up, scales and pivots quickly.
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default SoftwareDevlopment;
