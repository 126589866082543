import { Card, Row, Col } from "reactstrap";
import styles from "./styles.module.css";
export default function WhyUsCard({ src, title, para }) {
  return (
    <Card className={styles.whyUsCard}>
      <Row className={styles.image}>
        <Col>
          <img src={src} alt={title} width="40%" />
        </Col>
      </Row>
      <h3>{title}</h3>
      <p>{para}</p>
    </Card>
  );
}
