import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Container, Button } from "react-bootstrap";
import Assets from "../../Config/Assets";
import Aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

const Development = (props) => {
  const { develop } = props;

  const [getIMacWhite, setIMacWhite] = useState(false);
  const [getHover, setHover] = useState(false);
  const [getMeeting, setMeeting] = useState(false);
  const [getOnline, setOnline] = useState(false);
  const [getFlow, setFlow] = useState(false);
  const [getSprint, setSprint] = useState(false);
  const [getService, setService] = useState(false);
  const [getBrain, setBrain] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };
  return (
    <Container>
      <div className={styles.SoftwareDevlopmentMainHeader} id="OurServices">
        <br/><br/>
        <span>IT Outsourcing Solutions For Every Business</span>
        <div className={styles.SoftwareDevlopmentMainhove}></div>
        <div className="container">
          <div className="row">
            <div className="col-md-3" id={styles.cards}>
              {/* <div className={styles.MainDevelopCard}> */}
              <div
                className={styles.Developmentcard}
                onMouseOver={() => setIMacWhite(true)}
                onMouseLeave={() => setIMacWhite(false)}
              >
                <p>
                We provide high-quality, flexible, and innovative software solutions and comprehensive support in software ideation and execution.
                </p>
                <img
                  src={
                    getIMacWhite === false
                      ? Assets.Images.ComponentDrop
                      : Assets.Images.DropDownWhite
                  }
                />
                <img
                  src={
                    getIMacWhite === false
                      ? Assets.Images.IMac
                      : Assets.Images.IMacWhite
                  }
                  className={styles.cardIcon}
                />
                <pre>Custom Software Development</pre>
              </div>
            </div>
            <div className="col-md-3" id={styles.cards}>
              <div
                className={styles.Developmentcard}
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <p>
                  We offer dedicated support, maintenance, and bug-free
                  applications.
                </p>
                <img
                  src={
                    getHover === false
                      ? Assets.Images.ComponentDrop
                      : Assets.Images.DropDownWhite
                  }
                />
                <img
                  src={
                    getHover === false
                      ? Assets.Images.Applicationform
                      : Assets.Images.ApplicationformWhite
                  }
                  className={styles.cardIcon}
                />
                <pre>Application Testing</pre>
              </div>
            </div>
            <div className="col-md-3" id={styles.cards}>
              <div
                className={styles.Developmentcard}
                onMouseOver={() => setMeeting(true)}
                onMouseLeave={() => setMeeting(false)}
              >
                <p>
                  We provide tailored software solutions and assist our
                  customers in selecting the right technology stacks for their
                  solutions.
                </p>
                <img
                  src={
                    getMeeting === false
                      ? Assets.Images.ComponentDrop
                      : Assets.Images.DropDownWhite
                  }
                />
                <img
                  src={
                    getMeeting === false
                      ? Assets.Images.Meeting
                      : Assets.Images.MeetingWhite
                  }
                  className={styles.cardIcon}
                />
                <pre>IT Consulting</pre>
              </div>
            </div>
            <div className="col-md-3" id={styles.cards}>
              <div
                className={styles.Developmentcard}
                onMouseOver={() => setOnline(true)}
                onMouseLeave={() => setOnline(false)}
              >
                <p>
                We offer comprehensive application support and maintenance services, including fixing bugs and errors.
                  {/* in the application to */}
                  {/* improving its features and functionality. */}
                </p>
                <img
                  src={
                    getOnline === false
                      ? Assets.Images.ComponentDrop
                      : Assets.Images.DropDownWhite
                  }
                />
                <img
                  src={
                    getOnline === false
                      ? Assets.Images.OnlineSupport
                      : Assets.Images.WorkWhite
                  }
                  className={styles.cardIcon}
                />
                <pre>IT Maintanence And Support</pre>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-3" id={styles.cards}>
              {/* <div className={styles.MainDevelopCard}> */}
              <div
                className={styles.Developmentcard}
                onMouseOver={() => setFlow(true)}
                onMouseLeave={() => setFlow(false)}
              >
                <p>
                An outstanding model that allows you to have minimal time differences and no communication barriers with a client.
                </p>
                <img
                  src={
                    getFlow === false
                      ? Assets.Images.ComponentDrop
                      : Assets.Images.DropDownWhite
                  }
                />
                <img
                  src={
                    getFlow === false
                      ? Assets.Images.Flow
                      : Assets.Images.FlowWhite
                  }
                  className={styles.cardIcon}
                />
                <pre>Nearshore Development</pre>
              </div>
              {/* </div> */}
            </div>
            <div className="col-md-3" id={styles.cards}>
              <div
                className={styles.Developmentcard}
                onMouseOver={() => setSprint(true)}
                onMouseLeave={() => setSprint(false)}
              >
                <p>
                It gives short iterations, lowers maintenance costs, improves the user experience, and increases agility within the client's time and budget.
                </p>
                <img
                  src={
                    getSprint === false
                      ? Assets.Images.ComponentDrop
                      : Assets.Images.DropDownWhite
                  }
                />
                <img
                  src={
                    getSprint === false
                      ? Assets.Images.SprintIteration
                      : Assets.Images.SprintIterationWhite
                  }
                  className={styles.cardIcon}
                />
                <pre>Agile Software Development</pre>
              </div>
            </div>
            <div className="col-md -3" id={styles.cards}>
              <div
                className={styles.Developmentcard}
                onMouseOver={() => setService(true)}
                onMouseLeave={() => setService(false)}
              >
                <p>
                  Our IT System and Service Integration services are the
                  processes of integrating your sub-component systems.
                  {/* into one functional system &
            your disparate system run without a hitch */}
                </p>
                <img
                  src={
                    getService === false
                      ? Assets.Images.ComponentDrop
                      : Assets.Images.DropDownWhite
                  }
                />
                <img
                  src={
                    getService === false
                      ? Assets.Images.Service
                      : Assets.Images.ServiceWhite
                  }
                  className={styles.cardIcon}
                />
                <pre> IT System And Service Integration</pre>
              </div>
            </div>
            <div className="col-md-3" id={styles.cards}>
              <div
                className={styles.Developmentcard}
                onMouseOver={() => setBrain(true)}
                onMouseLeave={() => setBrain(false)}
              >
                <p>
                We empathise, clearly define our users' needs, and create relevant, unique, and innovative solutions.
                </p>
                <img
                  src={
                    getBrain === false
                      ? Assets.Images.ComponentDrop
                      : Assets.Images.DropDownWhite
                  }
                />
                <img
                  src={
                    getBrain === false
                      ? Assets.Images.BrainstormSkill
                      : Assets.Images.BrainstormSkillWhite
                  }
                  className={styles.cardIcon}
                />
                <pre>Design Thinking</pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default Development;
