import { Container, Row, Col } from "reactstrap";

import sol1 from "../../../Images/ZohoPng/rightplatform.jpg";
import sol2 from "../../../Images/ZohoPng/integration.jpg";
import sol3 from "../../../Images/ZohoPng/data centralization.jpg";
import sol4 from "../../../Images/ZohoPng/Benefits-of-Zoho-Marketing-Automation-1024x536.webp";
import sol5 from "../../../Images/ZohoPng/zoho social.jpg";
import sol6 from "../../../Images/ZohoPng/what-does-a-website-manager-do.webp";

import styles from "./styles.module.css";
import { useState } from "react";
export default function OurSolutions() {
  const [tab, setTab] = useState(1);
  return (
    <Container id="oursolutions" fluid className={styles.ourSolutions}>
      <h1 className="text-center">Our Solutions</h1>
      <Row className="justify-content-center">
        <Col xs="10" className={styles.whyUsCol}>
          To leverage a unified platform for CRM, marketing/Sales, social media,
          and website to drive customer acquisition effectively, TTIPL' S
          Unified Platform will support your strategic approach that integrates
          these components seamlessly. Here's a strategy tailored towards
          customer acquisition
        </Col>
      </Row>
      <Row className="justify-content-center" id={styles.tab}>
        <Col xs="10" className="text-center">
          <div className={styles.tabParent}>
            <span
              className={tab === 1 ? styles.active1 : styles.tab1}
              onClick={() => setTab(1)}
            >
              Right Platform
            </span>
            <span
              className={tab === 2 ? styles.active : styles.tab}
              onClick={() => setTab(2)}
            >
              Integration Planning
            </span>
            <span
              className={tab === 3 ? styles.active : styles.tab}
              onClick={() => setTab(3)}
            >
              Data Centralization
            </span>
            <span
              className={tab === 4 ? styles.active : styles.tab}
              onClick={() => setTab(4)}
            >
              Marketing Automation
            </span>
            <span
              className={tab === 5 ? styles.active : styles.tab}
              onClick={() => setTab(5)}
            >
              Social Media Management
            </span>
            <span
              className={tab === 6 ? styles.active2 : styles.tab2}
              onClick={() => setTab(6)}
            >
              Website Management
            </span>
          </div>
        </Col>
      </Row>
      <Row className={styles.hideTab}>
        <Col
          xs="10"
          className={tab === 1 ? styles.accordActiveTab : styles.accordTab}
          onClick={() => setTab(1)}
        >
          Right Platform
        </Col>
      </Row>

      <Row
        className={
          tab === 1 ? "mt-1  mt-lg-5 justify-content-center " : "d-none"
        }
      >
        <Col xs="10" lg="4" className="py-3 py-lg-0">
          <img src={sol1} alt="solution1" width="100%" />
        </Col>
        <Col xs="10" lg="4" className={styles.tabCol}>
          An all-encompassing platform providing comprehensive solutions for
          CRM, marketing/sales automation, social media management, and website
          management. This integrated ecosystem streamlines operations,
          centralizes workflows, and maximizes efficiency. With seamless
          integration across modules, businesses can optimize processes, drive
          engagement, and achieve their objectives effectively, all within a
          unified platform.
        </Col>
      </Row>

      <Row className={styles.hideTab}>
        <Col
          xs="10"
          className={tab === 2 ? styles.accordActiveTab : styles.accordTab}
          onClick={() => setTab(2)}
        >
          Integration Planning
        </Col>
      </Row>

      <Row
        className={
          tab === 2 ? "mt-1 mt-lg-5 justify-content-center " : "d-none"
        }
      >
        <Col xs="10" lg="4" className="py-3 py-lg-0">
          <img src={sol2} alt="solution1" width="100%" />
        </Col>
        <Col xs="10" lg="4" className={styles.tabCol}>
          Facilitate seamless communication and integration to synchronize
          customer data, campaign analytics, and relevant information across all
          modules. This interconnected ecosystem enhances data consistency,
          fosters collaboration, and enables informed decision-making. With
          synchronized systems, streamline operations, optimize marketing
          strategies, and deliver cohesive experiences to drive business growth
          and customer satisfaction.
        </Col>
      </Row>

      <Row className={styles.hideTab}>
        <Col
          xs="10"
          className={tab === 3 ? styles.accordActiveTab : styles.accordTab}
          onClick={() => setTab(3)}
        >
          Data Centralization
        </Col>
      </Row>

      <Row
        className={
          tab === 3 ? "mt-1 mt-lg-5 justify-content-center " : "d-none"
        }
      >
        <Col xs="10" lg="4" className="py-3 py-lg-0">
          <img src={sol3} alt="solution1" width="100%" />
        </Col>
        <Col xs="10" lg="4" className={styles.tabCol}>
          Consolidate customer data within the CRM module to establish a unified
          source of truth. Centralizing information streamlines processes,
          enhances data accuracy, and facilitates comprehensive insights into
          customer interactions. With a singular repository for all
          customer-related information, empower teams to make informed decisions
          and deliver personalized experiences at every touchpoint.
        </Col>
      </Row>

      <Row className={styles.hideTab}>
        <Col
          xs="10"
          className={tab === 4 ? styles.accordActiveTab : styles.accordTab}
          onClick={() => setTab(4)}
        >
          Marketing Automation
        </Col>
      </Row>

      <Row
        className={
          tab === 4 ? "mt-1 mt-lg-5 justify-content-center " : "d-none"
        }
      >
        <Col xs="10" lg="4" className="py-3 py-lg-0">
          <img src={sol4} alt="solution1" width="100%" />
        </Col>
        <Col xs="10" lg="4" className={styles.tabCol}>
          Leverage our platform's marketing/Sales automation capabilities to
          streamline lead nurturing, email marketing, and campaign management.
          Use customer data from the CRM module to create targeted
          marketing/Sales campaigns across multiple channels, including email,
          social media, and website.
        </Col>
      </Row>

      <Row className={styles.hideTab}>
        <Col
          xs="10"
          className={tab === 5 ? styles.accordActiveTab : styles.accordTab}
          onClick={() => setTab(5)}
        >
          Social Media Management
        </Col>
      </Row>
      {tab === 5 && (
        <Row
          className={
            tab === 5 ? "mt-1 mt-lg-5 justify-content-center " : "d-none"
          }
        >
          <Col xs="10" lg="4" className="py-3 py-lg-0">
            <img src={sol5} alt="solution1" width="100%" />
          </Col>
          <Col xs="10" lg="4" className={styles.tabCol}>
            Leverage our platform's robust suite of social media management
            tools to streamline post scheduling, enhance follower engagement,
            and meticulously analyze performance metrics. Gain invaluable
            insights into audience interactions, optimize content strategies,
            and elevate your brand's online presence with ease and efficiency.
          </Col>
        </Row>
      )}
      <Row className={styles.hideTab}>
        <Col
          xs="10"
          className={tab === 6 ? styles.accordActiveTab : styles.accordTab}
          onClick={() => setTab(6)}
        >
          Website Management
        </Col>
      </Row>

      <Row
        className={
          tab === 6 ? "mt-1 mt-lg-5 justify-content-center " : "d-none"
        }
      >
        <Col xs="10" lg="4" className="py-3 py-lg-0">
          <img src={sol6} alt="solution1" width="100%" />
        </Col>
        <Col xs="10" lg="4" className={styles.tabCol}>
          Utilize the comprehensive website management capabilities of our
          platform to effortlessly create and optimize your organization's
          website. Seamlessly customize design elements, enhance user
          experience, and maximize search engine visibility. With intuitive
          tools and advanced features, achieve your website goals efficiently
          and effectively, empowering your online presence for success.
        </Col>
      </Row>
    </Container>
  );
}
