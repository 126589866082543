import React from "react";
import { useState } from "react";
import Routing from "../Navigation/Routing";

const Base = () => {
  return (
    <>
      <Routing />
    </>
  );
};
export default Base;
