import React from "react";
import { useState, useEffect } from "react";
import styles from "./styles.module.css";
import { Container, Row, Col } from "react-bootstrap";
import MoreInfo from "../MoreInfo/Moreinfo";
import SoftwareDevlopment from "../SoftwareDevelopmentServices";
import Digital from "../ExpertiseToPower";
import Clients from "../OurClients";
import Development from "../Devlopment";
import Assets from "../../Config/Assets";
// import Popup from "../../Commpoents/Modal/popup/index.js";
import "../landingPage/style.css";
import "bootstrap/dist/css/bootstrap.css";
// import TTIPL from "../../Images/TTIPL8TH(1).mp4";
// import TTIPLCollab from "../../Images/TTIPLCollab.mp4";

import Carousel from "../../Commpoents/Carousel/index";
import { Link } from "react-router-dom";
import Popup from "../../Commpoents/Modal/popup";

let slides = [
  <img src={Assets.Images.VimarshiTTIPL1} alt="vimarshiTTIPL" />,
  <div className="position-relative">
    <img src={Assets.Images.ZohoTTIPL} alt="ZohoTTIPL" />
    <div className="pos">
      <button className="learn-more">
        <Link target="_blank" to="/zoho-implementation">
          <span className="circle" aria-hidden="true">
            <span className="icon arrow"></span>
          </span>
          <span className="button-text">Read More</span>
        </Link>
      </button>
    </div>
  </div>,
  <img src={Assets.Images.TNASDCTTIPL} alt="TNASDCTTIPL" />,
  <img src={Assets.Images.GallopTTIPL} alt="gallopTTIPL" />,
];

const LandingMainBackground = (props) => {
  const { develop, services, howwedo, ourClients } = props;

  const [open, setOpen] = useState({ banner1: false });
  let banner = sessionStorage.getItem("banner");
  useEffect(() => {
    if (!banner) {
      setOpen({ banner1: true });
    } else {
      setOpen({ banner1: false });
    }
  }, [banner]);
  // useEffect(() => {
  //   let timeOut;
  //   if (!banner) {
  //     timeOut = setTimeout(() => {
  //       setOpen({ banner1: false, banner2: true });
  //     }, 15000);
  //   }
  //   if (!banner) {
  //     setOpen((elem) => {
  //       return { ...elem, banner1: true };
  //     });
  //   } else if (banner) {
  //     setOpen((elem) => {
  //       return { ...elem, banner1: false };
  //     });
  //   }
  //   return () => {
  //     clearTimeout(timeOut);
  //   };
  // }, [banner]);
  const handleBanner1Close = () => {
    setOpen({ banner1: false, banner2: true });
    sessionStorage.setItem("banner", true);
  };
  // const handleBanner2Close = () => {
  //   setOpen({ banner1: false, banner2: false });
  //   sessionStorage.setItem("banner", true);
  // };
  const callback = function (index) {
    console.log("callback", index);
  };

  return (
    <div>
      <div className={styles.MainScroll}>
        <div className={styles.MainHeader}>
          <div className={styles.landimage}>
            <Container>
              <Row>
                <Col>
                  {/* <div className={styles.main}>
                  <div className={styles.LandingPageContent}>
                    <p>
                      We Design And Develop Digital Products, To Suit The
                      Requirements Of Top Clients In The IT Field
                    </p>
                  </div>
                  <div className={styles.Content}>
                    <span>We Help Drive Your Future Revenues</span>
                  </div>
                  <button className="learn-more"  >
                          <a href="/about"></a>
                          </button>
                  <div className={styles.LandingPageContent1}>
                    </div>
                  </div> */}

                  <div className={styles.animatedtitle}>
                    <div className={styles.texttop}>
                      <div>
                        <span>
                          We Design And Develop Digital Products, To Suit The
                          Requirements Of Clients In The IT Field
                        </span>
                      </div>
                    </div>
                    <div className={styles.textbottom}>
                      <div>We help drive your future revenues</div>
                    </div>
                  </div>

                  <div className={styles.btnMain}>
                    <div className={styles.btn}>
                      <div className={styles.Learn_moreMain}>
                        <button className="learn-more">
                          <a href="/zoho-marketing">
                            <span className="circle" aria-hidden="true">
                              <span className="icon arrow"></span>
                            </span>
                            <span className="button-text text-white">
                              Learn More
                            </span>
                          </a>
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          <Container>
            <MoreInfo />
          </Container>
          <SoftwareDevlopment services={services} />
          <Development develop={develop} />
          <Container>
            <Digital howwedo={howwedo} />
          </Container>

          <h6 className={styles.partnerShip}>Technical Partnerships</h6>
          <Container fluid>
            <Carousel
              slides={slides}
              autoplay={true}
              interval={4000}
              onSlideChange={callback}
            />
          </Container>

          <Clients ourClients={ourClients} />
        </div>
      </div>

      <Popup open={open.banner1} handleClose={handleBanner1Close} />

      {/* {open.banner2 && (
        <Popup
          src={TTIPLCollab}
          open={open.banner2}
          handleClose={handleBanner2Close}
        />
      )} */}
    </div>
  );
};
export default LandingMainBackground;
