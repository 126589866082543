import React from "react";
import { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styles from "./styles.module.css";
import "../../../src/App.css";
// import TTIPLLOGO from "../../Images/TTIPLlogo.png";
import TTIPLLOGO2 from "../../Images/TTIPLlogo2.png";
// import Development from "../Devlopment";
// import Career from "../Career";
// import Blogs from "../Blog";
import { Navigate } from "react-router-dom";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { Services } from "./../../Service/Services";
import Career from "./../Career/index";
import { Link } from "react-router-dom";

const NavbarLanding = (props) => {
  const handleClick = () => {
    navigate("/");
  };

  const navigate = useNavigate();

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const {
    navbar,
    scrollChange,
    develop,
    Contact,
    navbarscrollMain,
    howwedo,
    ourClients,
    Modal,
    Development,
  } = props;

  const { pathname } = useLocation();

  // const myFunction =() =>{
  //   let x = document.getElementById("myText").autofocus;
  // }

  const NavbarClickbutton = () => {
    var element = document.getElementById("navbarScroll");
    element.classList.add("show");
    element.classList
      .remove("show")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <>
      <Navbar
        ref={navbarscrollMain}
        expand="md"
        className={
          navbar === true
            ? styles.MainHeaderNavbar
            : isMobile
            ? styles.MainHeaderNavbar
            : ""
        }
      >
        {isMobile || navbar === true ? (
          <div className={styles.container}>
            <img
              onClick={() => handleClick()}
              src={TTIPLLOGO2}
              style={{ cursor: "pointer" }}
              id="Mainhead"
              className={styles.image1}
              alt="logo"
            />
            {/* <div className={styles.overlay}>
              <img
                onClick={() => handleClick()}
                className={styles.text}
                src={TTIPLLOGO2}
              />
            </div> */}
          </div>
        ) : (
          // <img
          //   to="/"
          //   src={TTIPLLOGO}
          //   onClick={() => handleClick()}
          //   className={styles.NavbarLogo}
          //   alt="logo"
          // />
          ""
        )}

        <Navbar.Toggle
          style={{ border: "0px" }}
          aria-controls="navbarScroll"
          id={styles.icons}
        />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="my-2 my-lg-0 m-auto"
            style={{
              maxHeight: "500px",
              width: "60%",
              justifyContent: "space-between",
            }}
            navbarScroll
          >
            <Link
              to="/"
              id={styles.navhead}
              className={pathname.split("/")[1] === "" && `${styles.active}`}
            >
              HOME
            </Link>
            <Link
              to="/#OurServices"
              // onClick={() => scrollChange(develop)}
              onClick={NavbarClickbutton}
              id={styles.navhead}
            >
              OUR SERVICES
            </Link>
            <Link
              to="/#HowWedo"
              onClick={NavbarClickbutton}
              id={styles.navhead}
              // onClick={() => scrollChange(howwedo)}
            >
              WHAT WE DO
            </Link>
            <Link
              to="/#OurClients"
              id={styles.navhead}
              onClick={NavbarClickbutton}
            >
              OUR CLIENTS
            </Link>
            <Link
              to="/about"
              id={styles.navhead}
              className={
                pathname.split("/")[1] === "about" && `${styles.active}`
              }
            >
              ABOUT US
            </Link>
            <Link
              to="/career"
              id={styles.navhead}
              className={
                pathname.split("/")[1] === "career" && `${styles.active}`
              }
            >
              CAREER
            </Link>
            {/* <Link to="/blog" id={styles.navhead}>
              BLOG
            </Link> */}
            {isMobile && (
              <Link
                onClick={NavbarClickbutton}
                id={styles.navhead}
                to="#footer"
              >
                CONTACT US
              </Link>
            )}
          </Nav>
          {navbar === true ? (
            <div>
              <Button
                to="#footer"
                onClick={NavbarClickbutton}
                // onClick={() => scrollChange(Contact)}
                className={isMobile ? styles.Contact_UsTwo : styles.Contact_Us}
              >
                Contact Us
              </Button>
            </div>
          ) : (
            ""
          )}
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};
export default NavbarLanding;
