import Idea from "../../Images/Round.png";
import "./style.css";

const DigitalConsultingServices = (props) => {
  return (
    <div id="HowWedo">
      <div className="SoftwareDevlopmentMainHeader">
        <br />
        <br />
        <span>Power Your Innovation & Growth</span>

        <h6 className="SoftwareDevlopmentContent">
          Work with senior technical and SME's to unlock growth, speed up
          transformation and increase business resilience.
        </h6>
        <h6 className="digital">
          We provide customized software consulting services
        </h6>

        <div id="wrapper">
          <div id="container">
            <ol class="static-org-chart">
              <li>
                <div class="staff_card">
                  <div class="staff_titxxxle">
                    <img src={Idea} alt="" />
                  </div>
                </div>
                <ol id="first">
                  <li>
                    <div>
                      <div>Software Assessment</div>
                    </div>
                  </li>
                  <li>
                    <div>
                      <div>Software Testing</div>
                    </div>
                  </li>
                  <li>
                    <div>
                      <div>Design Sprint</div>
                    </div>
                  </li>
                  <li>
                    <div>
                      <div>Deployment & Implementation</div>
                    </div>
                  </li>
                </ol>
                <ol id="second">
                  <li>
                    <div class="staff_card">
                      <div class="staff_title">Re-engineering & Migration</div>
                    </div>
                  </li>
                  <li>
                    <div class="staff_card">
                      <div class="staff_title">Software Security</div>
                    </div>
                  </li>
                  <li>
                    <div class="staff_card">
                      <div class="staff_title">Quality Assurance</div>
                    </div>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DigitalConsultingServices;
