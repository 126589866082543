import React, { useEffect } from "react";
import styles from "./styles.module.css";
import { Container, Row, Col } from "react-bootstrap";
import About_us from "../../Screens/About_us";
import Aos from "aos";
import "aos/dist/aos.css";
const AboutUsMainHeader = (props) => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className={styles.MainScroll}>
      <div className={styles.MainHeader}>
        <div className={styles.landimage}>
          <Container>
            <Row>
              <Col>
                <div className={styles.LandingPageContent} data-aos="fade-down">
                  <h6>Talent Takeaways </h6>
                  <br />
                  <h4>People Driven and Focused</h4>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div style={{ backgroundColor: "#F3F6F9" }}>
          <About_us />
        </div>
      </div>
    </div>
  );
};
export default AboutUsMainHeader;
