import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { Container, Row, Col } from "react-bootstrap";
import Mainblog from "../../Config/CommentData.js";

import myListEvenStyles from "./styles.module.css";
import myListOddStyles from "./styles.module.css";
import Aos from "aos";
import "../../Images/img1.png";
import "aos/dist/aos.css";

const Blogs = (props) => {
  const image = [
    {
      id: 1,
      card: 1,
      img: require("../../Images/img1.png"),
    },
    {
      id: 2,
      card: 2,
      img: require("../../Images/img2.jpeg"),
    },
    {
      id: 3,
      card: 3,
      img: require("../../Images/img3.jpeg"),
    },
    {
      id: 4,
      card: 4,
      img: require("../../Images/img4.jpeg"),
    },
  ];
  const [user, setUser] = useState([]);
  const fetchData = () => {
    return fetch("https://fia-uganda-edrms.com:3500/api/bloglist")
      .then((response) => {
        console.log("Response status:", response.status);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response.json();
      })
      .then((data) => {
        console.log("Data received:", data);

        setUser(data);
      })
      .catch((error) => {
        console.error("Error during fetch:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    console.log(user?.data?.result?.Cards, "user");
  }, [user]);

  const [allBlogs, setAllBlog] = useState([]);

  useEffect(() => {
    setAllBlog(Mainblog.Cards);
    console.log(allBlogs, "allBlogs");
  }, []);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  const regexUrl = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;

  return (
    <div>
      <div>
        <div className={style.MainScroll}>
          <div className={style.MainHeader}>
            <div className={style.careerlandimage}>
              <Container fluid className={style.Headertext}>
                <Row>
                  <Col>
                    <div
                      data-aos="fade-down"
                      className={style.LandingPageContent}
                    >
                      <p>OUR BLOG</p>
                      <h6>Think Learn Share</h6>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="check">
            {user?.data?.result?.Cards?.map((data, index) => {
              console.log(data, "return");
              const isEven = index % 2 === 0;
              const className = isEven
                ? myListEvenStyles.myListEven
                : myListOddStyles.myListOdd;
              return (
                <div key={data.id} className={className}>
                  <div className="row" id={style.rows}>
                    <div className="col-lg-6">
                      <a
                        className={style.link}
                        href={`/blog/${data.content.replace(regexUrl, "-")}/${
                          data.id
                        }`}
                      >
                        <div
                          className={style.card}
                          style={{ backgroundColor: `${data.color}` }}
                        >
                          <div data-aos="fade-down" className={style.zoomimg}>
                            <div key={index} style={{ height: "100% " }}>
                              <img src={image[index].img} alt="" />
                            </div>
                          </div>

                          <div className={style.text} data-aos="fade-up">
                            <span>{data.content}</span>
                            <p>&zwj;</p>
                            <h6>{data.date}</h6>
                            <h6>{data.readtime}</h6>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <p>&zwj;</p>
      </div>
    </div>
  );
};

export default Blogs;
