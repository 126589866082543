import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  //   CarouselControl,
  //   CarouselIndicators,
  //   CarouselCaption,
  Row,
  Col,
} from "reactstrap";

import hand from "../../Images/ZohoPng/23.png";
import create from "../../Images/ZohoPng/create.png";
import dream from "../../Images/ZohoPng/dream.png";

const items = [
  {
    src: hand,
    altText: "Slide 1",
    caption: [
      "Operates on auto-pilot to make your journey smoother",
      "Get Rid of Excel sheets",
      "Proper governance and reporting/dashboard",
      "Easy Forecast",
      "Automated customer management that empowers your business",
    ],
    key: 1,
  },
  {
    src: create,
    altText: "Slide 2",
    caption: [
      "Effortless system that works for your business",
      "Omni channel cloud-based system",
      "Risk-free client experience",
      " Unmatched marketing efforts and client success ",
      "Transparent Operations and Smooth Execution ",
    ],
    key: 2,
  },
  {
    src: dream,
    altText: "Slide 3",
    caption: [
      "Easy documented communication between your employees and customer",
      "Process driven business",
      " Seamless Integration with other systems in your organization",
      " 24×7 customer support from an experienced team ",
    ],
    key: 3,
  },
];

function InspireCreateDream(args) {
  //   const [step, setStep] = useState(0);
  const { step, setStep } = args;
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = step === items.length - 1 ? 0 : step + 1;
    setStep(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = step === 0 ? items.length - 1 : step - 1;
    setStep(nextIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <Row
          key={item.key}
          className="justify-content-center align-items-center"
        >
          <Col sm="4" className="text-center">
            <img src={item.src} alt={item.altText} width="50%" />
          </Col>
          <Col sm="6">
            <ol className="h5">
              {item.caption.map((elem) => {
                return <li className="mb-1">{elem}</li>;
              })}
            </ol>
          </Col>
        </Row>
      </CarouselItem>
    );
  });

  return (
    <Carousel activeIndex={step} next={next} previous={previous} {...args}>
      {slides}
    </Carousel>
  );
}

export default InspireCreateDream;
