import { useState, useEffect } from "react";
import CommentForm from "./CommentForm";
import Comment from "./Comment";
import "./index.css";
const Comments = ({
  onCommentChanges,
  onUserNameChange,
  onUserEmailChange,
  form,
  onHandleCommentDetail,
  formModal,
  handleFormModalOpen,
  handleFormModalClose,
  onSubmitForm,
  allComments,
  commentDetail,
  onHandleReply,
  onHandleDelete,
  onHandleClose,
  blogForm,
  onBlogUserEmailChange,
  onBlogUserNameChange,
  onBlogCommentChanges,
  validation,
  onFormSubmit,
  FormValidation,
  addLikeCommentToBlog,
}) => {
  const blogComment = () => {
    if (blogForm.comment === "") {
      alert("Comment cannot be empty!");
    } else {
      handleFormModalOpen(true);
      onHandleCommentDetail("blogComment");
    }
  };

  // const onFormSubmit = () => {
  //   handleFormModalOpen(true);
  // };

  return (
    <div className="comments">
      <div className="comment-form-title">Write comment</div>

      <CommentForm
        onCommentChanges={onBlogCommentChanges}
        onUserNameChange={onBlogUserNameChange}
        onUserEmailChange={onBlogUserEmailChange}
        form={blogForm}
        onSend={blogComment}
        formModal={formModal}
        handleFormModalOpen={handleFormModalOpen}
        handleFormModalClose={handleFormModalClose}
        onSubmitForm={onSubmitForm}
        submitLabel="Send"
        blogForm={blogForm}
        validation={validation}
        FormValidation={FormValidation}
      />

      <div className="comments-container">
        <h4 className="comments-title">
          Comments{" "}
          <span> ({allComments?.length ? allComments?.length : 0})</span>
        </h4>
        {allComments?.map((rootComment) => {
          return (
            <div className="dash" key={"comments" + rootComment.commentId}>
              <Comment
                key={rootComment.commentId}
                id={rootComment.commentId}
                commentId={rootComment.commentId}
                name={rootComment.userName}
                email={rootComment.userEmail}
                date={rootComment.createdAt}
                comment={rootComment.comment}
                isLiked={rootComment.isLiked}
                likeCount={rootComment.likeCount}
                onHandleReply={onHandleReply}
                onHandleDelete={onHandleDelete}
                commentDetail={commentDetail}
                onCommentChanges={onCommentChanges}
                onUserNameChange={onUserNameChange}
                onUserEmailChange={onUserEmailChange}
                form={form}
                onSend={onFormSubmit}
                formModal={formModal}
                handleFormModalOpen={handleFormModalOpen}
                handleFormModalClose={handleFormModalClose}
                onSubmitForm={onSubmitForm}
                addLikeCommentToBlog={addLikeCommentToBlog}
                submitLabel="Reply"
                closeReply={onHandleClose}
                LevelStatus="level1Reply"
              />

              {rootComment?.reply?.length > 0 &&
                rootComment?.reply.map((level1Data) => {
                  return (
                    <>
                      <Comment
                        key={level1Data.commentId}
                        commentId={rootComment.commentId}
                        level1Id={level1Data.replyId}
                        id={level1Data.replyId}
                        name={level1Data.userName}
                        email={level1Data.userEmail}
                        date={level1Data.createdAt}
                        comment={level1Data.comment}
                        isLiked={level1Data.isLiked}
                        likeCount={level1Data.likeCount}
                        onHandleReply={onHandleReply}
                        onHandleDelete={onHandleDelete}
                        commentDetail={commentDetail}
                        onCommentChanges={onCommentChanges}
                        onUserNameChange={onUserNameChange}
                        onUserEmailChange={onUserEmailChange}
                        form={form}
                        onSend={onFormSubmit}
                        formModal={formModal}
                        handleFormModalOpen={handleFormModalOpen}
                        handleFormModalClose={handleFormModalClose}
                        onSubmitForm={onSubmitForm}
                        submitLabel="Reply"
                        closeReply={onHandleClose}
                        LevelStatus="level2Reply"
                        addLikeCommentToBlog={addLikeCommentToBlog}
                      />
                      {level1Data?.reply?.length > 0 &&
                        level1Data?.reply.map((level2Data) => {
                          return (
                            <>
                              <Comment
                                key={level2Data.commentId}
                                commentId={rootComment.commentId}
                                level1Id={level1Data.replyId}
                                level2Id={level2Data.replyId}
                                id={level2Data.commentId}
                                name={level2Data.userName}
                                email={level2Data.userEmail}
                                date={level2Data.createdAt}
                                comment={level2Data.comment}
                                isLiked={level2Data.isLiked}
                                likeCount={level2Data.likeCount}
                                onHandleReply={onHandleReply}
                                onHandleDelete={onHandleDelete}
                                commentDetail={commentDetail}
                                onCommentChanges={onCommentChanges}
                                onUserNameChange={onUserNameChange}
                                onUserEmailChange={onUserEmailChange}
                                form={form}
                                onSend={onFormSubmit}
                                formModal={formModal}
                                handleFormModalOpen={handleFormModalOpen}
                                handleFormModalClose={handleFormModalClose}
                                onSubmitForm={onSubmitForm}
                                submitLabel="Reply"
                                closeReply={onHandleClose}
                                LevelStatus="level3Reply"
                              />
                              {level2Data?.reply?.length > 0 &&
                                level2Data?.reply.map((level3Data) => {
                                  return (
                                    <>
                                      <Comment
                                        key={level3Data.commentId}
                                        commentId={rootComment.commentId}
                                        level1Id={level1Data.replyId}
                                        level2Id={level2Data.replyId}
                                        level3Id={level3Data.replyId}
                                        id={level3Data.commentId}
                                        name={level3Data.userName}
                                        email={level3Data.userEmail}
                                        date={level3Data.createdAt}
                                        comment={level3Data.comment}
                                        isLiked={level3Data.isLiked}
                                        likeCount={level3Data.likeCount}
                                        onHandleReply={onHandleReply}
                                        onHandleDelete={onHandleDelete}
                                        commentDetail={commentDetail}
                                        onCommentChanges={onCommentChanges}
                                        onUserNameChange={onUserNameChange}
                                        onUserEmailChange={onUserEmailChange}
                                        form={form}
                                        onSend={onFormSubmit}
                                        formModal={formModal}
                                        handleFormModalOpen={
                                          handleFormModalOpen
                                        }
                                        handleFormModalClose={
                                          handleFormModalClose
                                        }
                                        onSubmitForm={onSubmitForm}
                                        submitLabel="Reply"
                                        closeReply={onHandleClose}
                                        LevelStatus="level4Reply"
                                      />
                                      {level3Data?.reply?.length > 0 &&
                                        level3Data?.reply.map((level4Data) => {
                                          return (
                                            <>
                                              <Comment
                                                key={level4Data.replyId}
                                                commentId={
                                                  rootComment.commentId
                                                }
                                                level1Id={level1Data.replyId}
                                                level2Id={level2Data.replyId}
                                                level3Id={level3Data.replyId}
                                                level4Id={level4Data.replyId}
                                                id={level4Data.commentId}
                                                name={level4Data.userName}
                                                email={level4Data.userEmail}
                                                date={level4Data.createdAt}
                                                comment={level4Data.comment}
                                                isLiked={level4Data.isLiked}
                                                likeCount={level4Data.likeCount}
                                                onHandleReply={onHandleReply}
                                                onHandleDelete={onHandleDelete}
                                                commentDetail={commentDetail}
                                                onCommentChanges={
                                                  onCommentChanges
                                                }
                                                onUserNameChange={
                                                  onUserNameChange
                                                }
                                                onUserEmailChange={
                                                  onUserEmailChange
                                                }
                                                form={form}
                                                onSend={onFormSubmit}
                                                formModal={formModal}
                                                handleFormModalOpen={
                                                  handleFormModalOpen
                                                }
                                                handleFormModalClose={
                                                  handleFormModalClose
                                                }
                                                onSubmitForm={onSubmitForm}
                                                submitLabel="Reply"
                                                closeReply={onHandleClose}
                                                LevelStatus="level5Reply"
                                              />
                                              {level4Data?.reply?.length > 0 &&
                                                level4Data?.reply.map(
                                                  (level5Data) => {
                                                    return (
                                                      <>
                                                        <Comment
                                                          key={
                                                            level5Data.replyId
                                                          }
                                                          commentId={
                                                            rootComment.commentId
                                                          }
                                                          level1Id={
                                                            level1Data.replyId
                                                          }
                                                          level2Id={
                                                            level2Data.replyId
                                                          }
                                                          level3Id={
                                                            level3Data.replyId
                                                          }
                                                          level4Id={
                                                            level4Data.replyId
                                                          }
                                                          level5Id={
                                                            level5Data.replyId
                                                          }
                                                          id={
                                                            level5Data.commentId
                                                          }
                                                          name={
                                                            level5Data.userName
                                                          }
                                                          email={
                                                            level5Data.userEmail
                                                          }
                                                          date={
                                                            level5Data.createdAt
                                                          }
                                                          comment={
                                                            level5Data.comment
                                                          }
                                                          isLiked={
                                                            level5Data.isLiked
                                                          }
                                                          likeCount={
                                                            level5Data.likeCount
                                                          }
                                                          onHandleReply={
                                                            onHandleReply
                                                          }
                                                          onHandleDelete={
                                                            onHandleDelete
                                                          }
                                                          commentDetail={
                                                            commentDetail
                                                          }
                                                          onCommentChanges={
                                                            onCommentChanges
                                                          }
                                                          onUserNameChange={
                                                            onUserNameChange
                                                          }
                                                          onUserEmailChange={
                                                            onUserEmailChange
                                                          }
                                                          form={form}
                                                          onSend={onFormSubmit}
                                                          formModal={formModal}
                                                          handleFormModalOpen={
                                                            handleFormModalOpen
                                                          }
                                                          handleFormModalClose={
                                                            handleFormModalClose
                                                          }
                                                          onSubmitForm={
                                                            onSubmitForm
                                                          }
                                                          submitLabel="Reply"
                                                          closeReply={
                                                            onHandleClose
                                                          }
                                                          LevelStatus="level6Reply"
                                                        />
                                                        {level5Data?.reply
                                                          ?.length > 0 &&
                                                          level5Data?.reply.map(
                                                            (level6Data) => {
                                                              return (
                                                                <>
                                                                  <Comment
                                                                    key={
                                                                      level6Data.replyId
                                                                    }
                                                                    commentId={
                                                                      rootComment.commentId
                                                                    }
                                                                    level1Id={
                                                                      level1Data.replyId
                                                                    }
                                                                    level2Id={
                                                                      level2Data.replyId
                                                                    }
                                                                    level3Id={
                                                                      level3Data.replyId
                                                                    }
                                                                    level4Id={
                                                                      level4Data.replyId
                                                                    }
                                                                    level5Id={
                                                                      level5Data.replyId
                                                                    }
                                                                    level6Id={
                                                                      level6Data.replyId
                                                                    }
                                                                    id={
                                                                      level6Data.commentId
                                                                    }
                                                                    name={
                                                                      level6Data.userName
                                                                    }
                                                                    email={
                                                                      level6Data.userEmail
                                                                    }
                                                                    date={
                                                                      level6Data.createdAt
                                                                    }
                                                                    comment={
                                                                      level6Data.comment
                                                                    }
                                                                    isLiked={
                                                                      level6Data.isLiked
                                                                    }
                                                                    likeCount={
                                                                      level6Data.likeCount
                                                                    }
                                                                    onHandleReply={
                                                                      onHandleReply
                                                                    }
                                                                    onHandleDelete={
                                                                      onHandleDelete
                                                                    }
                                                                    commentDetail={
                                                                      commentDetail
                                                                    }
                                                                    onCommentChanges={
                                                                      onCommentChanges
                                                                    }
                                                                    onUserNameChange={
                                                                      onUserNameChange
                                                                    }
                                                                    onUserEmailChange={
                                                                      onUserEmailChange
                                                                    }
                                                                    form={form}
                                                                    onSend={
                                                                      onFormSubmit
                                                                    }
                                                                    formModal={
                                                                      formModal
                                                                    }
                                                                    handleFormModalOpen={
                                                                      handleFormModalOpen
                                                                    }
                                                                    handleFormModalClose={
                                                                      handleFormModalClose
                                                                    }
                                                                    onSubmitForm={
                                                                      onSubmitForm
                                                                    }
                                                                    submitLabel="Reply"
                                                                    closeReply={
                                                                      onHandleClose
                                                                    }
                                                                    LevelStatus="level7Reply"
                                                                  />
                                                                  {level6Data
                                                                    ?.reply
                                                                    ?.length >
                                                                    0 &&
                                                                    level6Data?.reply.map(
                                                                      (
                                                                        level7Data
                                                                      ) => {
                                                                        return (
                                                                          <>
                                                                            <Comment
                                                                              key={
                                                                                level7Data.replyId
                                                                              }
                                                                              commentId={
                                                                                rootComment.commentId
                                                                              }
                                                                              level1Id={
                                                                                level1Data.replyId
                                                                              }
                                                                              level2Id={
                                                                                level2Data.replyId
                                                                              }
                                                                              level3Id={
                                                                                level3Data.replyId
                                                                              }
                                                                              level4Id={
                                                                                level4Data.replyId
                                                                              }
                                                                              level5Id={
                                                                                level5Data.replyId
                                                                              }
                                                                              level6Id={
                                                                                level6Data.replyId
                                                                              }
                                                                              level7Id={
                                                                                level7Data.replyId
                                                                              }
                                                                              id={
                                                                                level7Data.commentId
                                                                              }
                                                                              name={
                                                                                level7Data.userName
                                                                              }
                                                                              email={
                                                                                level7Data.userEmail
                                                                              }
                                                                              date={
                                                                                level7Data.createdAt
                                                                              }
                                                                              comment={
                                                                                level7Data.comment
                                                                              }
                                                                              isLiked={
                                                                                level7Data.isLiked
                                                                              }
                                                                              likeCount={
                                                                                level7Data.likeCount
                                                                              }
                                                                              onHandleReply={
                                                                                onHandleReply
                                                                              }
                                                                              onHandleDelete={
                                                                                onHandleDelete
                                                                              }
                                                                              commentDetail={
                                                                                commentDetail
                                                                              }
                                                                              onCommentChanges={
                                                                                onCommentChanges
                                                                              }
                                                                              onUserNameChange={
                                                                                onUserNameChange
                                                                              }
                                                                              onUserEmailChange={
                                                                                onUserEmailChange
                                                                              }
                                                                              form={
                                                                                form
                                                                              }
                                                                              onSend={
                                                                                onFormSubmit
                                                                              }
                                                                              formModal={
                                                                                formModal
                                                                              }
                                                                              handleFormModalOpen={
                                                                                handleFormModalOpen
                                                                              }
                                                                              handleFormModalClose={
                                                                                handleFormModalClose
                                                                              }
                                                                              onSubmitForm={
                                                                                onSubmitForm
                                                                              }
                                                                              submitLabel="Reply"
                                                                              closeReply={
                                                                                onHandleClose
                                                                              }
                                                                              LevelStatus="level8Reply"
                                                                            />
                                                                            {level7Data
                                                                              ?.reply
                                                                              ?.length >
                                                                              0 &&
                                                                              level7Data?.reply.map(
                                                                                (
                                                                                  level8Data
                                                                                ) => {
                                                                                  return (
                                                                                    <>
                                                                                      <Comment
                                                                                        key={
                                                                                          level8Data.replyId
                                                                                        }
                                                                                        commentId={
                                                                                          rootComment.commentId
                                                                                        }
                                                                                        level1Id={
                                                                                          level1Data.replyId
                                                                                        }
                                                                                        level2Id={
                                                                                          level2Data.replyId
                                                                                        }
                                                                                        level3Id={
                                                                                          level3Data.replyId
                                                                                        }
                                                                                        level4Id={
                                                                                          level4Data.replyId
                                                                                        }
                                                                                        level5Id={
                                                                                          level5Data.replyId
                                                                                        }
                                                                                        level6Id={
                                                                                          level6Data.replyId
                                                                                        }
                                                                                        level7Id={
                                                                                          level7Data.replyId
                                                                                        }
                                                                                        level8Id={
                                                                                          level8Data.replyId
                                                                                        }
                                                                                        id={
                                                                                          level8Data.commentId
                                                                                        }
                                                                                        name={
                                                                                          level8Data.userName
                                                                                        }
                                                                                        email={
                                                                                          level8Data.userEmail
                                                                                        }
                                                                                        date={
                                                                                          level8Data.createdAt
                                                                                        }
                                                                                        comment={
                                                                                          level8Data.comment
                                                                                        }
                                                                                        isLiked={
                                                                                          level8Data.isLiked
                                                                                        }
                                                                                        likeCount={
                                                                                          level8Data.likeCount
                                                                                        }
                                                                                        onHandleReply={
                                                                                          onHandleReply
                                                                                        }
                                                                                        onHandleDelete={
                                                                                          onHandleDelete
                                                                                        }
                                                                                        commentDetail={
                                                                                          commentDetail
                                                                                        }
                                                                                        onCommentChanges={
                                                                                          onCommentChanges
                                                                                        }
                                                                                        onUserNameChange={
                                                                                          onUserNameChange
                                                                                        }
                                                                                        onUserEmailChange={
                                                                                          onUserEmailChange
                                                                                        }
                                                                                        form={
                                                                                          form
                                                                                        }
                                                                                        onSend={
                                                                                          onFormSubmit
                                                                                        }
                                                                                        formModal={
                                                                                          formModal
                                                                                        }
                                                                                        handleFormModalOpen={
                                                                                          handleFormModalOpen
                                                                                        }
                                                                                        handleFormModalClose={
                                                                                          handleFormModalClose
                                                                                        }
                                                                                        onSubmitForm={
                                                                                          onSubmitForm
                                                                                        }
                                                                                        submitLabel="Reply"
                                                                                        closeReply={
                                                                                          onHandleClose
                                                                                        }
                                                                                        LevelStatus="level9Reply"
                                                                                      />
                                                                                      {level8Data
                                                                                        ?.reply
                                                                                        ?.length >
                                                                                        0 &&
                                                                                        level8Data?.reply.map(
                                                                                          (
                                                                                            level9Data
                                                                                          ) => {
                                                                                            return (
                                                                                              <>
                                                                                                <Comment
                                                                                                  key={
                                                                                                    level8Data.replyId
                                                                                                  }
                                                                                                  commentId={
                                                                                                    rootComment.commentId
                                                                                                  }
                                                                                                  level1Id={
                                                                                                    level1Data.replyId
                                                                                                  }
                                                                                                  level2Id={
                                                                                                    level2Data.replyId
                                                                                                  }
                                                                                                  level3Id={
                                                                                                    level3Data.replyId
                                                                                                  }
                                                                                                  level4Id={
                                                                                                    level4Data.replyId
                                                                                                  }
                                                                                                  level5Id={
                                                                                                    level5Data.replyId
                                                                                                  }
                                                                                                  level6Id={
                                                                                                    level6Data.replyId
                                                                                                  }
                                                                                                  level7Id={
                                                                                                    level7Data.replyId
                                                                                                  }
                                                                                                  level8Id={
                                                                                                    level8Data.replyId
                                                                                                  }
                                                                                                  level9Id={
                                                                                                    level9Data.replyId
                                                                                                  }
                                                                                                  id={
                                                                                                    level9Data.commentId
                                                                                                  }
                                                                                                  name={
                                                                                                    level9Data.userName
                                                                                                  }
                                                                                                  email={
                                                                                                    level9Data.userEmail
                                                                                                  }
                                                                                                  date={
                                                                                                    level9Data.createdAt
                                                                                                  }
                                                                                                  comment={
                                                                                                    level9Data.comment
                                                                                                  }
                                                                                                  isLiked={
                                                                                                    level9Data.isLiked
                                                                                                  }
                                                                                                  likeCount={
                                                                                                    level9Data.likeCount
                                                                                                  }
                                                                                                  onHandleReply={
                                                                                                    onHandleReply
                                                                                                  }
                                                                                                  onHandleDelete={
                                                                                                    onHandleDelete
                                                                                                  }
                                                                                                  commentDetail={
                                                                                                    commentDetail
                                                                                                  }
                                                                                                  onCommentChanges={
                                                                                                    onCommentChanges
                                                                                                  }
                                                                                                  onUserNameChange={
                                                                                                    onUserNameChange
                                                                                                  }
                                                                                                  onUserEmailChange={
                                                                                                    onUserEmailChange
                                                                                                  }
                                                                                                  form={
                                                                                                    form
                                                                                                  }
                                                                                                  onSend={
                                                                                                    onFormSubmit
                                                                                                  }
                                                                                                  formModal={
                                                                                                    formModal
                                                                                                  }
                                                                                                  handleFormModalOpen={
                                                                                                    handleFormModalOpen
                                                                                                  }
                                                                                                  handleFormModalClose={
                                                                                                    handleFormModalClose
                                                                                                  }
                                                                                                  onSubmitForm={
                                                                                                    onSubmitForm
                                                                                                  }
                                                                                                  submitLabel="Reply"
                                                                                                  closeReply={
                                                                                                    onHandleClose
                                                                                                  }
                                                                                                  LevelStatus="level10Reply"
                                                                                                />
                                                                                                {level9Data
                                                                                                  ?.reply
                                                                                                  ?.length >
                                                                                                  0 &&
                                                                                                  level9Data?.reply.map(
                                                                                                    (
                                                                                                      level10Data
                                                                                                    ) => {
                                                                                                      return (
                                                                                                        <>
                                                                                                          <Comment
                                                                                                            key={
                                                                                                              level8Data.replyId
                                                                                                            }
                                                                                                            commentId={
                                                                                                              rootComment.commentId
                                                                                                            }
                                                                                                            level1Id={
                                                                                                              level1Data.replyId
                                                                                                            }
                                                                                                            level2Id={
                                                                                                              level2Data.replyId
                                                                                                            }
                                                                                                            level3Id={
                                                                                                              level3Data.replyId
                                                                                                            }
                                                                                                            level4Id={
                                                                                                              level4Data.replyId
                                                                                                            }
                                                                                                            level5Id={
                                                                                                              level5Data.replyId
                                                                                                            }
                                                                                                            level6Id={
                                                                                                              level6Data.replyId
                                                                                                            }
                                                                                                            level7Id={
                                                                                                              level7Data.replyId
                                                                                                            }
                                                                                                            level8Id={
                                                                                                              level8Data.replyId
                                                                                                            }
                                                                                                            level9Id={
                                                                                                              level9Data.replyId
                                                                                                            }
                                                                                                            level10Id={
                                                                                                              level10Data.replyId
                                                                                                            }
                                                                                                            id={
                                                                                                              level10Data.commentId
                                                                                                            }
                                                                                                            name={
                                                                                                              level10Data.userName
                                                                                                            }
                                                                                                            email={
                                                                                                              level10Data.userEmail
                                                                                                            }
                                                                                                            date={
                                                                                                              level10Data.createdAt
                                                                                                            }
                                                                                                            comment={
                                                                                                              level10Data.comment
                                                                                                            }
                                                                                                            isLiked={
                                                                                                              level10Data.isLiked
                                                                                                            }
                                                                                                            likeCount={
                                                                                                              level10Data.likeCount
                                                                                                            }
                                                                                                            onHandleReply={
                                                                                                              onHandleReply
                                                                                                            }
                                                                                                            onHandleDelete={
                                                                                                              onHandleDelete
                                                                                                            }
                                                                                                            commentDetail={
                                                                                                              commentDetail
                                                                                                            }
                                                                                                            onCommentChanges={
                                                                                                              onCommentChanges
                                                                                                            }
                                                                                                            onUserNameChange={
                                                                                                              onUserNameChange
                                                                                                            }
                                                                                                            onUserEmailChange={
                                                                                                              onUserEmailChange
                                                                                                            }
                                                                                                            form={
                                                                                                              form
                                                                                                            }
                                                                                                            onSend={
                                                                                                              onFormSubmit
                                                                                                            }
                                                                                                            formModal={
                                                                                                              formModal
                                                                                                            }
                                                                                                            handleFormModalOpen={
                                                                                                              handleFormModalOpen
                                                                                                            }
                                                                                                            handleFormModalClose={
                                                                                                              handleFormModalClose
                                                                                                            }
                                                                                                            onSubmitForm={
                                                                                                              onSubmitForm
                                                                                                            }
                                                                                                            submitLabel="Reply"
                                                                                                            closeReply={
                                                                                                              onHandleClose
                                                                                                            }
                                                                                                            LevelStatus="level11Reply"
                                                                                                          />
                                                                                                        </>
                                                                                                      );
                                                                                                    }
                                                                                                  )}
                                                                                              </>
                                                                                            );
                                                                                          }
                                                                                        )}
                                                                                    </>
                                                                                  );
                                                                                }
                                                                              )}
                                                                          </>
                                                                        );
                                                                      }
                                                                    )}
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                      </>
                                                    );
                                                  }
                                                )}
                                            </>
                                          );
                                        })}
                                    </>
                                  );
                                })}
                            </>
                          );
                        })}
                    </>
                  );
                })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Comments;
