import React, { useState } from "react";
import "./styles.css";
import { Modal, Form, Col, Container, Row } from "react-bootstrap";

import axios from "axios";

import ReCAPTCHA from "react-google-recaptcha";

const CareerModal = (props) => {
  const { open, handleClose } = props;

  const recaptchaRef = React.createRef();

  const [getdata, setData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    comments: "",
    uploadFile: {
      data: "",
      type: "",
    },
    service: "",
    status: 1,
  });

  const handleCloseModal = () => {
    setData({
      name: null,
      email: null,
      phoneNumber: null,
      comments: null,
      uploadFile: {
        data: null,
        type: null,
      },
      service: null,
      status: 1,
    });
    handleClose();
  };

  const handleCloseModal1 = (e) => {
    e.preventDefault();
    setData({
      name: null,
      email: null,
      phoneNumber: null,
      comments: null,
      uploadFile: {
        data: null,
        type: null,
      },
      service: null,
      status: 1,
    });
    handleClose();
  };

  const carrermodal = async (e) => {
    e.preventDefault();
    let nameValidation = /(^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z]$)/;

    const captcha = recaptchaRef.current.getValue();

    if (getdata?.name === "" || getdata?.name == null) {
      alert("Please Enter Name");
      return false;
    } else if (!nameValidation.test(getdata?.name)) {
      alert("Please Enter Valid Name");
      return false;
    }
    let phoneNumberValidation =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    if (getdata?.phoneNumber === "" || getdata?.phoneNumber == null) {
      alert("Please Enter Phone Number");
      return false;
    } else if (!phoneNumberValidation.test(getdata?.phoneNumber)) {
      alert("Please Enter 10 Phone Number");
      return false;
    }

    if (getdata?.email === "") {
      alert("Please Enter Email");
      return false;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(getdata?.email)
    ) {
      alert("Please Enter Valid EmailId");
      return false;
    }

    if (getdata?.uploadFile.type === "" || getdata?.uploadFile.type == null) {
      alert("Please uploadFile the file");
      return false;
    }

    if (getdata?.service === "") {
      alert("Please Select Your Service");
      return false;
    }
    if (getdata?.comments === "") {
      alert("Please Enter Comments");
      return false;
    }

    if (getdata?.status === "") {
      alert("please select status is required");
      return false;
    }
    if (!captcha) {
      alert("Please Complete Recaptcha To Submit");
      return false;
    }

    console.log(getdata, "getdatasdfghyju");

    axios
      .post("https://fia-uganda-edrms.com:3000/api/Careers", getdata)
      .then((res) => {
        console.log("first232244", res);
        if (res.data?.statusCode === 200) {
          alert("Your Application has been Sent Successfully");
          handleClose(false);
          handleCloseModal1();
        }
      });
  };

  const handleFileUpdate = (e) => {
    let files = e.target.files;
    console.log("file", files);
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onloadend = function () {
      let base64data = reader.result;
      let baseData = base64data.split(",", 2);
      let obj = {};
      console.log("mainData1111", obj);
      obj.type = files[0].type;
      obj.data = baseData[1];
      let myArray = obj.type.split("/");
      obj.type = myArray[1];
      let uploadData = { ...getdata };
      uploadData.uploadFile = obj;
      console.log("mainData", obj.type);
      setData(uploadData);
    };
  };

  return (
    <>
      <Modal show={open} centered size="xl">
        <Modal.Header
          className="modalhead"
          closeButton
          onHide={handleCloseModal}
        >
          <Modal.Title className="modalheader">JOIN WITH US</Modal.Title>
        </Modal.Header>
        <Modal.Body className="Modalbody">
          <Form onSubmit={carrermodal}>
            <Container id="modalcontainer">
              <Row>
                <Col xs={12} md={6} id="colhead">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="mb-3 labelcontent">
                      Name <span className="labelColor">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="inputcontent"
                      placeholder="Write your name here.."
                      autoFocus
                      // name="Name"
                      value={getdata.name}
                      onChange={(e) =>
                        setData({ ...getdata, name: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} id="colhead">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="mb-3 labelcontent">
                      Upload your CV <span className="labelColor">*</span>
                    </Form.Label>
                    <Form.Control
                      class="form-control  form-control-lg"
                      type="file"
                      multiple
                      size="lg"
                      accept=".pdf,.doc"
                      className="inputcontent1"
                      placeholder="All File Size must be below 2MB.
                      Only Following extension files could be uploades .PDF,Word. "
                      autoFocus
                      //value={uploadFile}
                      onChange={(e) => handleFileUpdate(e)}

                      //  onChange={(e) => { uploadFile.push(e.target.files[0]) }}
                    />
                  </Form.Group>
                  <p className="mb-3 lh-1 text-break alertmgs">
                    {/* Only Following extension files could be uploades .PDF,Word. */}
                    Only following extension files should be upload(PDF,Word).
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} id="colhead">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="mb-3 labelcontent">
                      Phone <span className="labelColor">*</span>
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      name="PhoneNumber"
                      maxlength="10"
                      className="inputcontent"
                      placeholder="Write your Phone number here.."
                      autoFocus
                      value={getdata.phoneNumber}
                      onChange={(e) =>
                        setData({ ...getdata, phoneNumber: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} id="colhead">
                  <div className="form-group">
                    <label className="mb-3" for="services">
                      Services<span className="labelColor"> *</span>
                    </label>
                    <select
                      id="services"
                      name="service"
                      value={getdata.service}
                      onChange={(e) =>
                        setData({ ...getdata, service: e.target.value })
                      }
                      className="form-control"
                      required
                      autoComplete="off"
                    >
                      <option value="">-- Select One --</option>
                      <option value="Mobile Apps Development">
                        Mobile Apps Development
                      </option>
                      <option value="UX Researchers">UX Researchers</option>
                      <option value="DevOps Engineers">DevOps Engineers</option>
                      <option value="QA Engineers">QA Engineers</option>
                      <option value="Full-Stack Developers">
                        Full-Stack Developers
                      </option>
                      <option value="UX/UI Designers">UX/UI Designers</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} id="colhead">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="mb-3 labelcontent">
                      E-mail <span className="labelColor">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      type="email"
                      className="inputcontent"
                      placeholder="Write your email here.."
                      autoFocus
                      value={getdata?.email}
                      name="email"
                      onChange={(e) =>
                        setData({ ...getdata, email: e.target.value })
                      }
                      // setAddress({ ...address, [e.target.name]: e.target.value });
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} id="colhead">
                  <Form.Group
                    className="mb-3 "
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className="mb-3">Comments</Form.Label>
                    <Form.Control
                      className="inputcontent"
                      as="textarea"
                      rows={1}
                      placeholder="Tell About Your Self"
                      value={getdata.comments}
                      onChange={(e) =>
                        setData({ ...getdata, comments: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <ReCAPTCHA
                    className="d-flex justify-content-center"
                    sitekey="6LeN63ApAAAAAImM5G-h5f-OIzG8zugOW8Z2Om3B"
                    ref={recaptchaRef}
                  />
                </Col>
              </Row>

              <div className="Submitcontent">
                <div className="mt-3 lh-1 text-break ">
                  <input
                    type="checkbox"
                    name="Select"
                    value={getdata.status}
                    onChange={(e) =>
                      setData({ ...getdata, status: e.target.value })
                    }
                  />
                  I want to receive regular(listen) updates about Talent
                  Takeaways
                </div>
                <div className="Mainbtn">
                  <div className="btn">
                    <button class="close" aria-label="Close">
                      Send
                    </button>
                  </div>
                  <div className="btn1">
                    <button
                      class="btn bg-danger"
                      aria-label="Close"
                      onClick={handleCloseModal1}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </Container>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CareerModal;
