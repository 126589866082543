import React, { useEffect } from "react";
import styles from "./styles.module.css";
// import { Button } from 'react-bootstrap';
import TTIPLMOREINFOLOGO from "../../Images/TTIPLlogo2.png";
import Aos from "aos";
import "aos/dist/aos.css";
const MoreInfo = () => {
  // useEffect(() => {
  //   Aos.init({ duration: 1000 });
  // }, []);
  return (
    <div className={styles.MoreInfoHeader}>
      <div className={styles.MoreInfoHeader1}>
        <img
          // data-aos="fade-right"
          data-aos-duration="1000"
          data-aos="fade-down"
          src={TTIPLMOREINFOLOGO}
          className={styles.MoreInfoHeader2}
          alt="moreinfo"
        />
        <div
          className={styles.MVPContent}
          // data-aos="fade-left"
          data-aos-duration="1000"
          data-aos="fade-up"
        >
          <p>Are you a budding startup? </p>
          <p>Do you need an expert support?</p>
          <p>Do you want to grow exponentially?</p>
          {/* <Button className={styles.More_info}>More info</Button> */}
        </div>
      </div>
    </div>
  );
};
export default MoreInfo;
