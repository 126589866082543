import styles from "./styles.module.css";

import { Row, Col, Button } from "reactstrap";

import { MdCall } from "react-icons/md";

import HeroImage from "../../../Images/ZohoPng/HeroImage.png";
import { useState } from "react";
import FooterModal from "../../Modal/FooterModal";

export default function HeroSection() {
  const [open, setOpen] = useState();
  return (
    <section className={styles.heroSection}>
      <Row className="justify-content-center align-items-center">
        <Col lg="6" className={styles.middleCard}>
          <h1>
            <span className="d-block text-center">
              Unified Marketing Platform
            </span>
            <span className="d-block text-center">For </span>
            <span className="d-block text-center">Sales & Marketing Teams</span>
          </h1>
          <p className={styles.paragraph}>
            Welcome to TTIPL's Unified Marketing Platform Experience! We're
            excited to introduce our dynamic trio of marketing and sales
            solutions designed to revolutionize the way you manage, market, and
            socialize your campaigns and events.
          </p>
        </Col>
        <Col xs="4" className="text-center">
          <img src={HeroImage} alt="heroimage" width="70%" />
        </Col>
      </Row>

      <div className={styles.pos}>
        <div className={styles.btn}>
          <div className={styles.Learn_moreMain}>
            <button className={styles.learn_more}>
              <a href="#footer">
                <span className={styles.circle} aria-hidden="true">
                  <span className={`${styles.icon} ${styles.arrow}`}></span>
                </span>
                <span className={styles.button_text}>Contact Us</span>
              </a>
            </button>
          </div>
        </div>
      </div>

      {/* <Row className="justify-content-center">
        <Col xs="6" className="text-center">
          <Button
            size="md"
            className={styles.heroButton}
            onClick={() => setOpen(true)}
          >
            Contact Us <MdCall className={styles.phone} />
          </Button>
        </Col>
      </Row> */}

      {/* <Row className="justify-content-center mt-5">
        <Col xs="6" className="text-center">
          <img src={HeroImage} alt="heroimage" width="65%" />
        </Col>
      </Row> */}
      <FooterModal open={open} handleClose={() => setOpen(false)} />
    </section>
  );
}
