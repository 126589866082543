import Applicationform from "../Images/ApplicationForm.png";
import IMac from "../Images/iMacSettings.png";
import Meeting from "../Images/Meeting.png";
import OnlineSupport from "../Images/OnlineSupport.png";
import Flow from "../Images/Flow.png";
import BrainstormSkill from "../Images/BrainstormSkill.png";
import Service from "../Images/Service.png";
import SprintIteration from "../Images/SprintIteration.png";
import ComponentDrop from "../Images/ComponentDrop.png";
import HexawareTechnologies from "../Images/HexawareTechnologies.png";
import AspireSystems from "../Images/AspireSystems.png";
import BigBasket from "../Images/BigBasket.png";
import HDFCStandardLifInsurance from "../Images/HDFCStandardLifInsurance.png";
import Nasscom from "../Images/NASSCOM.png";
import NSR from "../Images/nsrlogo.png";

import oracleindustry from "../Images/oracleindustry.png";
import SAP from "../Images/SAP.png";
import WordPresscom from "../Images/WordPresscom.png";
import Angular from "../Images/Angular.png";
import IMacWhite from "../Images/iMacWhite.png";
import ApplicationformWhite from "../Images/ApplicationFormWhite.png";
import MeetingWhite from "../Images/MeetingWite.png";
import FlowWhite from "../Images/FlowWhite.png";
import ServiceWhite from "../Images/ServiceWhite.png";
import SprintIterationWhite from "../Images/IterationWhite.png";
import BrainstormSkillWhite from "../Images/KnowWhite.png";
import WorkWhite from "../Images/WorkWhite.png";
import DropDownWhite from "../Images/DropDownWhite.png";
import Location from "../Images/location.png";
import Phone from "../Images/Phone.png";
import Main from "../Images/Mail.png";
import Facebook from "../Images/Facebook.png";
import LinkedIn from "../Images/LinkedIn.png";
import Instagram from "../Images/Instagram.png";
import Mailbutton from "../Images/Mailbutton.png";
import UpSquared from "../Images/UpSquared.png";
import iMacSettingsSvg from "../Images/iMacSettingsSvg.svg";
import ELINASERVICE from "../Images/ELINASERVICE.png";
import IBRIDGE from "../Images/IBRIDGE.png";
import MLHUD_ICON from "../Images/MLHUD_ICON.png";
import world_vision from "../Images/world_vision.png";
import logocnt from "../Images/logocnt.png";
import Original_Logo_edi from "../Images/Original_Logo_edi.svg";
import PHP from "../Images/PHP.png";
import React_Iamge from "../Images/React_Iamge.png";
import Laravel from "../Images/Laravel.jpg";
import Nodejs from "../Images/Nodejs.png";
import net from "../Images/net.png";
import Native from "../Images/Native.png";
import Drupal from "../Images/Drupal.jpg";
import Knomadix from "../Images/Knomadix.png";
import MFI from "../Images/mfi.png";
import Nityo from "../Images/Nityo.jpeg";
import Unimity from "../Images/unimity.png";
import joeden from "../Images/joeden.jpg";
import kravchuk from "../Images/kravchuk.png";
import fesenko from "../Images/fesenko.png";
import linktin from "../Images/linktin.png";
import ICON from "../Images/ICON.png";
import careerpic from "../Images/careerPic.png";
import careertick from "../Images/careertick.png";
import CarouselImage1 from "../Images/CarouselImage1.png";
import CarouselImage2 from "../Images/CarouselImage2.png";
import CarouselImage3 from "../Images/CarouselImage3.png";
import CarouselImage4 from "../Images/CarouselImage4.png";
import CarouselImage5 from "../Images/CarouselImage5.png";
import CarouselImage6 from "../Images/CarouselImage6.png";
import CarouselImage7 from "../Images/CarouselImage7.png";
import CarouselImage8 from "../Images/CarouselImage8.png";

import blog1 from "../Images/blog1.png";
import design from "../Images/design.png";
import fintech from "../Images/fintech.png";
import Img1 from "../Images/img1.png";
import Img2 from "../Images/img2.jpeg";
import Img3 from "../Images/img3.jpeg";
import Img4 from "../Images/img4.jpeg";
import Img5 from "../Images/img5.jpg";
import Img6 from "../Images/img6.png";
import userImg from "../Images/userImg.png";
import bg_designer from "../Images/bg_designer.png";
import bg_full_stack from "../Images/bg_full_stack.png";
import bg_mobile from "../Images/bg_mobile.png";
import devops from "../Images/devops.png";
import qa from "../Images/qa.png";
import ux_research from "../Images/ux_research.png";
import pic1 from "../Images/pic1.png";
import pic2 from "../Images/pic2.png";
import pic3 from "../Images/pic3.png";
import pic4 from "../Images/pic4.png";
import pic5 from "../Images/pic5.png";
import Elangovansirwithoutbg from "../Images/ElangovanSirwithoutbg.png";
import down from "../Images/down_arrow_animation.gif";
import sendicon from "../Images/sendicon.png";
import Newyear from "../Images/Newyear_banner.png";
import close from "../Images/close.png";
import Employee_Benefits from "../Images/Employee_Benefits.png";
import android from "../Images/android.jpg";
import aws from "../Images/Aws.jpg";
import md from "../Images/md.jpg";
import cyberarkpas from "../Images/cyberarkpas.png";
import ios from "../Images/ios-logo.jpg";
import ml from "../Images/ml.jpg";
import WordPress from "../Images/wordpress-.png";
import mulesoft from "../Images/mulesoft.jpg";
import oracle from "../Images/oracle.png";
import kony from "../Images/kony.png";
import postgre from "../Images/postgre.png";
import pega from "../Images/pega.jpeg";
import pentaho from "../Images/pentaho.jpg";
import worksoft from "../Images/worksoft.jpg";
import sap from "../Images/SAP.png";
import Aezion from "../Images/Aezion Inc.jpeg";
import Girmiti from "../Images/Girmiti software.jpeg";
import paripoorna from "../Images/Paripoorna-Logo.png";
import PRODAPT from "../Images/PRODAPT.png";
import PEOL from "../Images/PEOL.png";
import OMNICELL from "../Images/OMNICELL.png";
import Zenith from "../Images/Zenith.png";
import Bornfire from "../Images/Bornfire.png";
import RYAN from "../Images/RYAN.png";

import KumarSir from "../Images/KumarSir.jpg";
import Anbukani from "../Images/Anbukani.png";
import Azar from "../Images/Azar.JPEG";
import Keeruthika from "../Images/Keeruthika.jpeg";
import Sam from "../Images/Sam.jpg";
import SarathM from "../Images/Sarath M.jpg";
import Sarath from "../Images/Sarath.jpg";
import Saravanan from "../Images/Saravanan.jpg";
import Karthika from "../Images/Karthika.jpeg";
import Bhuvana from "../Images/Bhuvana.jpg";
import SYED from "../Images/SYED.JPG";
import management from "../Images/management.png";

import heart from "../Images/heartIcon.png";

import careerlanding_img from "../Images/careerlanding_img.png";
import upbtn from "../Images/upbtn.png";
import URA from "../Images/URA-logo.png";
import MLHUD from "../Images/Uganda-logo.png";
import TNASDC from "../Images/TNASDC-logo.png";
import NanMudhalvan from "../Images/nan-mudhalvan-logo.png";
import Vimarshi from "../Images/Vimarshi.png";
import Elangovansir from "../Images/ElangovanSir.jpg";
import TTIPLwithZoho from "../Images/TTIPLwithZoho.png";
import TTIPLwithGallop from "../Images/TTIPLGallob.png";
import VimarshiCollab from "../Images/VimarshiCollab.png";
import VimarshiwithTTIPL from "../Images/VimarshiTTIPL.png";
import TTIPLwithTNASDC from "../Images/TNASDCTalent.png";
import GallopTTIPL from "../Images/ZohoPng/car3.png";
import VimarshiTTIPL1 from "../Images/ZohoPng/car1.png";
import TNASDCTTIPL from "../Images/ZohoPng/car4.png";
import ZohoTTIPL from "../Images/ZohoPng/car2.png";
const Images = {
  heart,
  KumarSir,
  Anbukani,
  Karthika,
  Azar,
  Bhuvana,
  Keeruthika,
  Sam,
  SarathM,
  Sarath,
  Saravanan,
  SYED,
  management,
  Applicationform,
  IMac,
  Meeting,
  OnlineSupport,
  Flow,
  SprintIteration,
  Service,
  BrainstormSkill,
  ComponentDrop,
  HexawareTechnologies,
  AspireSystems,
  BigBasket,
  HDFCStandardLifInsurance,
  Nasscom,
  NSR,
  oracleindustry,
  SAP,
  WordPresscom,
  Angular,
  IMacWhite,
  ApplicationformWhite,
  MeetingWhite,
  FlowWhite,
  ServiceWhite,
  SprintIterationWhite,
  BrainstormSkillWhite,
  WorkWhite,
  DropDownWhite,
  Main,
  Phone,
  Facebook,
  LinkedIn,
  Instagram,
  Location,
  Mailbutton,
  UpSquared,
  iMacSettingsSvg,
  ELINASERVICE,
  IBRIDGE,
  MLHUD_ICON,
  world_vision,
  logocnt,
  Original_Logo_edi,
  PHP,
  React_Iamge,
  Laravel,
  Nodejs,
  net,
  Native,
  Drupal,
  Knomadix,
  MFI,
  Nityo,
  Unimity,
  joeden,
  kravchuk,
  fesenko,
  linktin,
  ICON,
  careerpic,
  careertick,
  CarouselImage1,
  CarouselImage2,
  CarouselImage3,
  CarouselImage4,
  CarouselImage5,
  CarouselImage6,
  CarouselImage7,
  CarouselImage8,

  blog1,
  design,
  fintech,
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  down,
  userImg,
  bg_designer,
  bg_full_stack,
  bg_mobile,
  devops,
  qa,
  ux_research,
  pic1,
  pic2,
  pic3,
  pic4,
  pic5,
  Newyear,
  close,
  Employee_Benefits,
  android,
  aws,
  md,
  cyberarkpas,
  ios,
  ml,
  WordPress,
  mulesoft,
  oracle,
  kony,
  postgre,
  pega,
  pentaho,
  worksoft,
  sap,
  Aezion,
  Girmiti,
  paripoorna,
  PRODAPT,
  PEOL,
  OMNICELL,
  Zenith,
  Bornfire,
  RYAN,
  upbtn,
  careerlanding_img,
  sendicon,
  URA,
  MLHUD,
  TNASDC,
  NanMudhalvan,
  Vimarshi,
  Elangovansir,
  TTIPLwithZoho,
  TTIPLwithGallop,
  VimarshiCollab,
  TTIPLwithTNASDC,
  VimarshiwithTTIPL,
  Elangovansirwithoutbg,
  VimarshiTTIPL1,
  ZohoTTIPL,
  GallopTTIPL,
  TNASDCTTIPL,
};

export default {
  Images,
};
