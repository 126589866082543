import Clients from "../../Commpoents/Zoho/Unified Platform/Clients";
import HeroSection from "../../Commpoents/Zoho/Unified Platform/HeroSection";
import OurClients from "../../Commpoents/Zoho/Unified Platform/OurClients";
import OurFeatures from "../../Commpoents/Zoho/Unified Platform/OurFeatures";
import OurSolutions from "../../Commpoents/Zoho/Unified Platform/OurSolutions";
import ToolsUsed from "../../Commpoents/Zoho/Unified Platform/ToolsUsed";
import WhyUS from "../../Commpoents/Zoho/Unified Platform/WhyUS";
import MFI from "../../Images/ZohoPng/MFI.png";
import ELINA from "../../Images/ZohoPng/elinaservice.png";
import Accordion2 from "../../Commpoents/Zoho/Unified Platform/Accordion2";
import Accordion from "../../Commpoents/Zoho/Unified Platform/Accordion";

import styles from "./styles.module.css";

export default function ZohoPage() {
  return (
    <>
      <HeroSection />
      <WhyUS />
      <ToolsUsed />
      <OurFeatures />
      <OurSolutions />
      <OurClients />
      <section className={styles.clientSec}>
        <Clients
          src={ELINA}
          header="Enhancing Marketing Efficiency"
          subHeader="Implementing Unified Marketing & Sales Automation Platform for Elina's
          by TTIPL"
          className={styles.marginBottom}
        >
          <Accordion2 />
        </Clients>
        <Clients
          src={MFI}
          header="Improving Marketing / Sales Effectiveness"
          subHeader="Deployment of Integrated Marketing & Sales Automation Platform for MFI Document Solutions by TTIPL"
        >
          <Accordion />
        </Clients>
      </section>
    </>
  );
}
