import React, { useCallback, useEffect, useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";
import { FaPhoneAlt } from "react-icons/fa";
import styles from "./styles.module.css";

import poster1 from "../../Images/www..png";
import poster3 from "../../Images/Zoho c 1 w 1/Unified Marketing Platform Post.png";
import poster4 from "../../Images/Zoho c 1 w 1/Zoho Forms Post .png";
import poster5 from "../../Images/Zoho c 1 w 1/c1w-1.mp4";
const items = [
  {
    src: poster5,
    altText: "Slide 5",
    btnText: "Contact Us",
    key: 1,
  },
  {
    src: poster1,
    altText: "Slide 1",
    btnText: "Contact Us",
    key: 2,
  },

  {
    src: poster3,
    altText: "Slide 3",
    btnText: "Contact Us",
    key: 3,
  },
  {
    src: poster4,
    altText: "Slide 4",
    btnText: "Contact Us",
    key: 4,
  },
];

function SLIDER(args) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = useCallback(() => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }, [animating, activeIndex]);

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => next(), 20000);
    return () => clearInterval(interval);
  }, [next]);
  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
        style={{ position: "relative" }}
      >
        {item.key === 1 ? (
          <>
            <video
              src={item.src}
              alt={item.altText}
              width="100%"
              autoPlay
              muted
              loop
            ></video>
            <a
              href="https://zcmp.in/hjlv"
              className={styles.contactBtn}
              target="_blank"
              rel="noreferrer"
            >
              <FaPhoneAlt />
            </a>
          </>
        ) : (
          <>
            <img src={item.src} alt={item.altText} width="100%" />
            <a
              href="https://zcmp.in/hjlv"
              className={styles.contactBtn}
              target="_blank"
              rel="noreferrer"
            >
              <FaPhoneAlt />
            </a>
          </>
        )}
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      interval={18000}
      {...args}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
}

export default SLIDER;
