import { useEffect, useState } from "react";
import { BiSend } from "react-icons/bi";
import BlogModal from "../../Commpoents/Modal/BlogModal/index";
import Mainblog from "../../Config/CommentData";
import { useParams } from "react-router-dom";
const CommentForm = ({
  validation,
  onCommentChanges,
  onUserNameChange,
  onUserEmailChange,
  form,
  onSend,
  formModal,
  blogForm,
  handleFormModalOpen,
  handleFormModalClose,
  onSubmitForm,
  closeReply,
  submitLabel,
  commentDetail,
  commentId,
  level1Id,
  level2Id,
  level3Id,
  level4Id,
  level5Id,
  level6Id,
  level7Id,
  level8Id,
  level9Id,
  level10Id,
  FormValidation
}) => {
  const onSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <textarea
          id="comment"
          name="comment"
          cols="45"
          rows="6"
          placeholder=" Share your comments..."
          maxlength="65525"
          required=""
          className="comment-form-textarea"
          value={form.comment}
          onChange={onCommentChanges}
        />  
        <div className="buttonStyle">
          {closeReply ? (
            <button className="comment-form-button" onClick={closeReply}>
              Close
            </button>
          ) : null}
          <button className="comment-form-button" onClick={onSend}>
            {submitLabel} <BiSend className="sendicon" />
          </button>
        </div>
      </form>

      {/* <Comments data={data} /> */}

      <BlogModal
        onUserNameChange={onUserNameChange}
        onUserEmailChange={onUserEmailChange}
        form={form}
        show={formModal}
        handleFormModalClose={handleFormModalClose}
        handleFormModalOpen={handleFormModalOpen}
        onSubmitForm={onSubmitForm}
        commentDetail={commentDetail}
        blogForm={blogForm}
        commentId={commentId}
        level1Id={level1Id}
        level2Id={level2Id}
        level3Id={level3Id}
        level4Id={level4Id}
        level5Id={level5Id}
        level6Id={level6Id}
        level7Id={level7Id}
        level8Id={level8Id}
        level9Id={level9Id}
        level10Id={level10Id}
        validation={validation}
        FormValidation={FormValidation}
      />
    </div>
  );
};

export default CommentForm;
