import CommentForm from "./CommentForm";
import { AiOutlineHeart, AiTwotoneHeart } from "react-icons/ai";
import Assets from "../../Config/Assets";
import PropTypes from "prop-types";

import "./index.css";
import { useState, useEffect } from "react";
const Comment = ({
  id,
  name,
  email,
  date,
  comment,
  isLiked,
  likeCount,
  onHandleReply,
  onHandleDelete,
  onCommentChanges,
  onUserNameChange,
  onUserEmailChange,
  form,
  onSend,
  formModal,
  handleFormModalOpen,
  handleFormModalClose,
  onSubmitForm,
  submitLabel,
  closeReply,
  commentDetail,
  LevelStatus,
  commentId,
  level1Id,
  level2Id,
  level3Id,
  level4Id,
  level5Id,
  level6Id,
  level7Id,
  level8Id,
  level9Id,
  level10Id,
  blogForm,
  onHandleCommentDetail,
  addLikeCommentToBlog,
}) => {
  const levelNumber = LevelStatus?.slice(5, 6);
  const twoDigitNumber = LevelStatus?.slice(5, 7);

  const blogEmail = () => {
    if (blogForm.userEmail === "") {
      alert("Comment cannot be empty!");
    } else {
      handleFormModalOpen(true);
      onHandleCommentDetail("blogEmail");
    }
  };

  let newMargin = 0;
  if (!isNaN(twoDigitNumber)) {
    newMargin = 45 * (twoDigitNumber - 1);
  } else if (levelNumber) {
    newMargin = 45 * (levelNumber - 1);
  }

  const [heart, SetHeart] = useState(false);
  const [isheart, SetIsheart] = useState(false);

  const handelClick = () => {
    SetHeart(!heart);
    SetIsheart(!isheart);
  };

  return (
    <div key={id} className="comment">
      <div
        className="comment-right-part"
        style={{
          marginLeft: !isNaN(levelNumber) ? newMargin + "px" : "0px",
          width: !isNaN(levelNumber) ? `calc(100% - ${newMargin}px)` : "100%",
        }}
      >
        <div className="Maincontainer">
          <div className="commentcontainer">
            <div className="comment-image-container">
              <img src={Assets.Images.userImg} className="img" alt="icon" />
            </div>
            <div className="comment-author">
              {name}
              {/* - response to -{name} */}
            </div>
          </div>
          <div className="commentcontainer">
            <div className="comment-content">
              <div className="date">{date}</div>
            </div>
            <div>
              {/* {isLiked ? (
                <AiOutlineHeart size={20} onClick={() => handelClick()} />
              ) : (
                <AiTwotoneHeart
                  size={20}
                  onClick={() => addLikeCommentToBlog()}
                  style={{ color: heart ? "red" : "gray" }}
                />
              )} */}
              
            </div>
          </div>
        </div>
        <div className="comment-text">{comment}</div>
        {LevelStatus === "level1Reply" ? (
          <div className="comment-actions">
            <div
              className="comment-action"
              onClick={() => {
                onHandleReply(id, LevelStatus);
              }}
            >
              Reply
            </div>
            {/* <div
          className="comment-actio"
          onClick={() => {
            if (LevelStatus === "level1Reply") {
              onHandleDelete(LevelStatus, commentId);
            } else if (LevelStatus === "level2Reply") {
              onHandleDelete(LevelStatus, commentId, level1Id);
            } else if (LevelStatus === "level3Reply") {
              onHandleDelete(LevelStatus, commentId, level1Id, level2Id);
            } else if (LevelStatus === "level4Reply") {
              onHandleDelete(
                LevelStatus,
                commentId,
                level1Id,
                level2Id,
                level3Id
              );
            } else if (LevelStatus === "level5Reply") {
              onHandleDelete(
                LevelStatus,
                commentId,
                level1Id,
                level2Id,
                level3Id,
                level4Id
              );
            } else if (LevelStatus === "level6Reply") {
              onHandleDelete(
                LevelStatus,
                commentId,
                level1Id,
                level2Id,
                level3Id,
                level4Id,
                level5Id
              );
            } else if (LevelStatus === "level7Reply") {
              onHandleDelete(
                LevelStatus,
                commentId,
                level1Id,
                level2Id,
                level3Id,
                level4Id,
                level5Id,
                level6Id
              );
            } else if (LevelStatus === "level8Reply") {
              onHandleDelete(
                LevelStatus,
                commentId,
                level1Id,
                level2Id,
                level3Id,
                level4Id,
                level5Id,
                level6Id,
                level7Id
              );
            } else if (LevelStatus === "level9Reply") {
              onHandleDelete(
                LevelStatus,
                commentId,
                level1Id,
                level2Id,
                level3Id,
                level4Id,
                level5Id,
                level6Id,
                level7Id,
                level8Id
              );
            } else if (LevelStatus === "level10Reply") {
              onHandleDelete(
                LevelStatus,
                commentId,
                level1Id,
                level2Id,
                level3Id,
                level4Id,
                level5Id,
                level6Id,
                level7Id,
                level8Id,
                level9Id
              );
            } else if (LevelStatus === "level11Reply") {
              onHandleDelete(
                LevelStatus,
                commentId,
                level1Id,
                level2Id,
                level3Id,
                level4Id,
                level5Id,
                level6Id,
                level7Id,
                level8Id,
                level9Id,
                level10Id
              );
            } else {
              onHandleDelete();
            }
          }}
        >
          Delete
        </div> */}
          </div>
        ) : null}
      </div>
      {commentDetail.id === id && commentDetail.status === LevelStatus ? (
        <CommentForm
          onCommentChanges={onCommentChanges}
          onUserNameChange={onUserNameChange}
          onUserEmailChange={onUserEmailChange}
          form={form}
          onSend={onSend}
          formModal={formModal}
          handleFormModalOpen={handleFormModalOpen}
          handleFormModalClose={handleFormModalClose}
          onSubmitForm={onSubmitForm}
          submitLabel={submitLabel}
          closeReply={closeReply}
          commentDetail={commentDetail}
          commentId={commentId}
          level1Id={level1Id}
          level2Id={level2Id}
          level3Id={level3Id}
          level4Id={level4Id}
          level5Id={level5Id}
          level6Id={level6Id}
          level7Id={level7Id}
          level8Id={level8Id}
          level9Id={level9Id}
          level10Id={level10Id}
        />
      ) : null}
    </div>
  );
};

export default Comment;

Comment.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  date: PropTypes.string,
  comment: PropTypes.string,
  onHandleReply: PropTypes.func,
  onHandleDelete: PropTypes.func,
};
