import { create } from "apisauce";

const rolesApiClient = create({

    baseURL: 'http://fia-uganda-edrms.com:7777/api/',
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
    timeout: 50000,
});

function contact_form(con_name,con_email,con_phone_number,con_message) {
    return rolesApiClient
        .post("contact_form", {
            con_name: con_name,
            con_email:con_email,
            con_phone_number:con_phone_number,
            con_message:con_message,
        })
        .then((response) => {
            if (response.ok) {
                console.log(response.data);
                return response.data;
            } else if (response.problem === "TIMEOUT_ERROR") {
                return response.problem;
            } else {
                return null;
            }
        });
}   

export const Services = {
    contact_form
}