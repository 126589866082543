import Button from "react-bootstrap/Button";
import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";

const BlogModal = (props) => {
  const {
    validation,
    onUserNameChange,
    onUserEmailChange,
    form,
    show,

    handleFormModalClose,
    onSubmitForm,
    commentDetail,
    commentId,
    level1Id,
    level2Id,
    level3Id,
    level4Id,
    level5Id,
    level6Id,
    level7Id,
    level8Id,
    level9Id,
  } = props;

  return (
    <div>
      <div>
        <Modal show={show} onHide={handleFormModalClose} centered size="md">
          <Modal.Header closeButton>
            <Modal.Title className="modalheader"></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ background: "#f3f6f9" }}>
            <Form>
              <Container>
                <Row style={{ justifyContent: "center", padding: "1em " }}>
                  <Col xs={12} md={10}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="Label">
                        Email Id <span className="labelColor">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="please enter your email"
                        value={form?.userEmail}
                        onChange={onUserEmailChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Name <span className="labelColor">*</span>
                      </Form.Label>
                      <Form.Control
                        type="name"
                        placeholder="please enter your name"
                        value={form?.userName}
                        onChange={onUserNameChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Container>
            </Form>
          </Modal.Body>
          <Modal.Footer style={{ background: "#f3f6f9" }}>
            <Button variant="secondary" onClick={handleFormModalClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                if (commentDetail?.status === "level1Reply") {
                  onSubmitForm(commentId);
                } else if (commentDetail?.status === "level2Reply") {
                  onSubmitForm(commentId, level1Id);
                } else if (commentDetail?.status === "level3Reply") {
                  onSubmitForm(commentId, level1Id, level2Id);
                } else if (commentDetail?.status === "level4Reply") {
                  onSubmitForm(commentId, level1Id, level2Id, level3Id);
                } else if (commentDetail?.status === "level5Reply") {
                  onSubmitForm(
                    commentId,
                    level1Id,
                    level2Id,
                    level3Id,
                    level4Id
                  );
                } else if (commentDetail?.status === "level6Reply") {
                  onSubmitForm(
                    commentId,
                    level1Id,
                    level2Id,
                    level3Id,
                    level4Id,
                    level5Id
                  );
                } else if (commentDetail?.status === "level7Reply") {
                  onSubmitForm(
                    commentId,
                    level1Id,
                    level2Id,
                    level3Id,
                    level4Id,
                    level5Id,
                    level6Id
                  );
                } else if (commentDetail?.status === "level8Reply") {
                  onSubmitForm(
                    commentId,
                    level1Id,
                    level2Id,
                    level3Id,
                    level4Id,
                    level5Id,
                    level6Id,
                    level7Id
                  );
                } else if (commentDetail?.status === "level9Reply") {
                  onSubmitForm(
                    commentId,
                    level1Id,
                    level2Id,
                    level3Id,
                    level4Id,
                    level5Id,
                    level6Id,
                    level7Id,
                    level8Id
                  );
                } else if (commentDetail?.status === "level10Reply") {
                  onSubmitForm(
                    commentId,
                    level1Id,
                    level2Id,
                    level3Id,
                    level4Id,
                    level5Id,
                    level6Id,
                    level7Id,
                    level8Id,
                    level9Id
                  );
                } else {
                  validation();
                }
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
export default BlogModal;
