import { Container, Row, Col } from "reactstrap";

import crm from "../../../Images/ZohoPng/crm.png";
import crmplus from "../../../Images/ZohoPng/crm-.png";
import social from "../../../Images/ZohoPng/social.png";
import marketing from "../../../Images/ZohoPng/marketing.png";

import styles from "./styles.module.css";

const TOOLS = [
  { src: crm, title: "Zoho CRM" },
  { src: crmplus, title: "Zoho CRM+" },
  { src: marketing, title: "Zoho Marketing" },
  { src: social, title: "Zoho Social" },
];

export default function ToolsUsed() {
  return (
    <Container fluid className={styles.toolsUsed}>
      <h1>Tools Used</h1>
      <Row className="justify-content-center">
        {TOOLS.map((elem) => {
          return (
            <Col md="5" lg="2" className={styles.toolsUsedCol}>
              <div className="text-center">
                <img src={elem.src} alt={elem.title} width="40%" />

                <h3>{elem.title}</h3>
              </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
