import { Container, Row, Col } from "reactstrap";
import styles from "./styles.module.css";

import MFI from "../../../Images/ZohoPng/MFI.png";
import ELINA from "../../../Images/ZohoPng/elinaservice.png";
import Design from "../../../Images/ZohoPng/our client svg.svg";

export default function OurClients() {
  return (
    <Container id="ourclients" fluid className={styles.ourClients}>
      <h1 className="text-center ">Our Clients</h1>
      <Row className="justify-content-center mt-4">
        <Col xs="8" lg="2" className="my-4 text-center">
          <img src={ELINA} alt="" width="100%" id={styles.elina} />
        </Col>
        <Col xs="8" lg="2" className="my-4 text-center">
          <img src={MFI} alt="" width="100%" id={styles.mfi} />
        </Col>
      </Row>
      <div className={styles.design}>
        <img src={Design} alt="" width="100%" height="100%" />
      </div>
    </Container>
  );
}
