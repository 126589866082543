import { Container, Row, Col } from "reactstrap";
import WhyUsCard from "./WhyUsCard";

import styles from "./styles.module.css";

import WhyUS1 from "../../../Images/ZohoPng/whyus1.png";
import WhyUS2 from "../../../Images/ZohoPng/whyus2.png";
import WhyUS3 from "../../../Images/ZohoPng/whyus3.png";
import WhyUS4 from "../../../Images/ZohoPng/whyus4.png";

const WHYCARDCONTENT = [
  {
    src: WhyUS1,
    title: "Achieve Greater Efficiency",
    para: "Streamline event management with CRM's tools for contact management and lead tracking.",
  },
  {
    src: WhyUS2,
    title: "Enhance Engagement",
    para: "Foster relationships with personalized email campaigns through unified Marketing/Sales platform.",
  },
  {
    src: WhyUS3,
    title: "Boost Visibility",
    para: "Amplify your Campaign/event's reach with Social's scheduling and analytics.",
  },
  {
    src: WhyUS4,
    title: "Seamless Integration",
    para: "Enjoy enhanced collaboration across CRM Plus Marketing Plus Social Media Plus Website for elevated Campaign/ Event strategies.",
  },
];
export default function WhyUS() {
  return (
    <Container id="whyus" fluid className={styles.whyUs}>
      <h1 className="text-center">Why Us</h1>
      <Row className="justify-content-center">
        <Col lg="8" className={styles.whyUsCol}>
          Unleash the Full Potential of Your Campaign/Events with Our TTIPL’S
          unified marketing and sales platform. By integrating CRM,
          Marketing/Sales, Social Media and Website into your Campaign/ event
          strategy, you can streamline processes, enhance communication, and
          maximize your efforts to drive a greater ROI. Let's collaborate to
          make your Elina event a resounding success!
        </Col>
      </Row>
      <Row className="justify-content-center">
        {WHYCARDCONTENT.map((elem) => {
          return (
            <Col md="6" lg="2">
              <WhyUsCard src={elem.src} title={elem.title} para={elem.para} />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
