import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import TTIPLLOGO from "../../Images/TTIPLlogo2.png";
import Assets from "../../Config/Assets";
import FooterModal from "../../Commpoents/Modal/FooterModal/index.js";
import { useNavigate } from "react-router-dom";
const Footer = (props) => {
  const {
    navbar,
    Contact,
    scrollChange,
    navbarscrollMain,
    develop,
    howwedo,
    ourClients,
  } = props;

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const [showButton, setShowButton] = useState(false);

  const handleClicknavigate = () => {
    navigate("/about");
  };

  useEffect(() => {
    const handlescrollButtonVisibility = () => {
      window.pageYOffset > 100 ? setShowButton(true) : setShowButton(false);
    };
    window.addEventListener("scroll", handlescrollButtonVisibility);
    return () => {
      window.removeEventListener("scroll", handlescrollButtonVisibility);
    };
  }, []);
  const handleScrolltoTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer>
      <div className={styles.footer} id="footer">
        <div className={styles.footerSub}>
          <div className={styles.l_footer}>
            <img
              src={TTIPLLOGO}
              className={styles.MainheaderFooterLogo}
              alt="logo"
            />
            <div className={styles.FooterLocationContent}>
              <img src={Assets.Images.Location} alt="location" />
              {/* <span>
                1st Floor, Guna Complex Main Building, New No: 443 & 445, Old
                No: 304 & 305, Anna Salai, Teynampet, Chennai,Tamil Nadu-600018,
                India.
              </span> */}
              <span>
                Door No.93, Apoorva Towers, First Floor, Velachery Main Road,
                Little Mount, Chennai, Tamil Nadu , India - 600015.
              </span>
            </div>
            <div className={styles.FooterPhone}>
              <img src={Assets.Images.Phone} alt="location" />
              <span>+91 44 4798 7556</span>
            </div>

            <div className={styles.FooterMain}>
              <img src={Assets.Images.Main} alt="location" />
              <span>hr@talentakeaways.com</span>
            </div>
            <div className={styles.map}>
              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.9543024204345!2d80.24488991464587!3d13.038580790811958!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52664c58a7f1ff%3A0xf2d4a1a93983b6da!2sGuna%20Complex%2C%20443%2C%20Anna%20Salai%2C%20Teynampet%2C%20Chennai%2C%20Tamil%20Nadu%20600018!5e0!3m2!1sen!2sin!4v1624553776643!5m2!1sen!2sin"></iframe> */}
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3887.377480039692!2d80.223525!3d13.011616999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDAwJzQxLjgiTiA4MMKwMTMnMjQuNyJF!5e0!3m2!1sen!2sin!4v1717388388495!5m2!1sen!2sin"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="container">
            <div className="row" id={styles.rows}>
              <div className="col-lg-3">
                <div className={styles.MenuMain}>
                  <h2 className={styles.HeaderMenu}>Menu</h2>
                  <a href="/" className={styles.Menu1}>
                    <span>Home</span>
                  </a>
                  <a
                    href="/#OurServices"
                    className={styles.Menu1}
                    onClick={() => scrollChange(develop)}
                  >
                    <span>Our services</span>
                  </a>
                  <a
                    href="/#HowWedo"
                    className={styles.Menu1}
                    onClick={() => scrollChange(howwedo)}
                  >
                    <span>What we do</span>
                  </a>
                  <a
                    href="/#OurClients"
                    className={styles.Menu1}
                    onClick={() => scrollChange(ourClients)}
                  >
                    <span>Our clients</span>
                  </a>
                  <a href="/about" className={styles.Menu1}>
                    <span>About Us</span>
                  </a>
                  <a href="/career" className={styles.Menu1}>
                    <span>Career</span>
                  </a>
                  <a href="/blog" className={styles.Menu1}>
                    <span>Blog</span>
                  </a>
                </div>
              </div>

              <div className="col-lg-3">
                <div className={styles.MenuMain1}>
                  <h2 className={styles.HeaderMenu1}>Services</h2>
                  <span className={styles.Menu2}>
                    Customer Development Software
                  </span>
                  <span className={styles.Menu2}>Application Testing</span>
                  {/* <span className={styles.Menu2}>How we do</span> */}
                  <span className={styles.Menu2}>IT Consulting</span>
                  <span className={styles.Menu2}>IT Maintanence & Support</span>
                  <span className={styles.Menu2}>Nearshore Development</span>
                  <span className={styles.Menu2}>
                    Agile Software Development
                  </span>
                  <span className={styles.Menu2}>
                    IT System & Service Integration
                  </span>
                  <span className={styles.Menu2}>Design Thinking</span>
                  <a
                    href="/zoho-implementation"
                    target="_blank"
                    className={styles.zoho}
                  >
                    Zoho Consulation
                  </a>
                </div>
              </div>
              <div className="col-lg-3">
                <div className={styles.MenuMain2}>
                  <h2 className={styles.HeaderMenu2}>Industries</h2>
                  <span className={styles.Menu3}>Healthcare</span>
                  <span className={styles.Menu3}>E - governance</span>
                  <span className={styles.Menu3}>Education</span>
                  <span className={styles.Menu3}>BFSI</span>
                  <span className={styles.Menu3}>NGO</span>
                  <div className={styles.b_footerImg}></div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className={styles.MenuMain}>
                  <h2 className={styles.HeaderMenu}>Technologies</h2>
                  <span className={styles.Menu4}>Flutter</span>
                  <span className={styles.Menu4}>Python(Djano)</span>
                  <span className={styles.Menu4}>.NET Core</span>
                  <span className={styles.Menu4}>Node JS</span>
                  <span className={styles.Menu4}>React JS</span>
                  <span className={styles.Menu4}>React Native</span>
                  <span className={styles.Menu4}>Custom iOS app</span>
                  <span className={styles.Menu4}>Development Services</span>
                  <span className={styles.Menu4}>Custom Android</span>
                  <span className={styles.Menu4}>
                    Application Development Services
                  </span>
                  {showButton && (
                    <div className={"scrolltotop"}>
                      <div className={styles.back_to_top}>
                        <button
                          className={styles.back_1}
                          onClick={() => handleScrolltoTop(navbarscrollMain)}
                        >
                          <img src={Assets.Images.upbtn} alt="location" />
                        </button>
                      </div>
                    </div>
                  )}
                  <div className={styles.Contact_UsButtonHeader}>
                    <button
                      ref={Contact}
                      onClick={handleClick}
                      className={styles.Contact_UsButton}
                    >
                      <img
                        src={Assets.Images.Mailbutton}
                        className={styles.MainIcon}
                        alt="location"
                      />
                      Contact Us
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.Icon}>
          <a href="https://www.instagram.com/talentakeaways_infotech/?igshid=YmMyMTA2M2Y%3D">
            <img
              src={Assets.Images.Instagram}
              alt="location"
              style={{ margin: ".3rem" }}
            />
          </a>
          <a href="https://in.linkedin.com/company/talent-takeaways-consulting-india-pvt-ltd">
            <img
              src={Assets.Images.LinkedIn}
              alt="location"
              style={{ margin: ".3rem " }}
            />
          </a>
          <a href="https://www.facebook.com/Talenttakeaways/?ref=bookmarks">
            <img
              src={Assets.Images.Facebook}
              alt="location"
              style={{ margin: ".3rem" }}
            />
          </a>
        </div>
        <div className={styles.b_footer}>
          <div className={styles.b_footerTxt}>
            <p>Copyright © talentakeaways 2023. All rights reserved.</p>
          </div>
        </div>
        <FooterModal open={open} handleClose={() => setOpen(false)} />
      </div>
    </footer>
  );
};
export default Footer;
