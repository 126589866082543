import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./styles.module.css";
import { Container, Button } from "react-bootstrap";
import SwiperCore, {
  Pagination,
  Thumbs,
  Navigation,
  FreeMode,
  Autoplay,
} from "swiper";

import "swiper/swiper-bundle.min.css";
import Assets from "../../Config/Assets";

SwiperCore.use([Thumbs, Autoplay]);
const Clients = (props) => {
  const { ourClients } = props;

  return (
    <div className={styles.headerClients} id="OurClients">
      <Container>
        <div>
          <br />
          <br />
          <h6 className={styles.Clients}>Our Clients</h6>
        </div>

        <section loop="true" className={styles.warpper}>
          <div className={styles.trackClient}>
            <div className={styles.slide}>
              <img
                className={styles.swiperImage0}
                src={Assets.Images.Aezion}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.swiperImage1}
                src={Assets.Images.world_vision}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.swiperImage2}
                src={Assets.Images.ELINASERVICE}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.swiperImage3}
                src={Assets.Images.Girmiti}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.swiperImage4}
                src={Assets.Images.paripoorna}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.swiperImage5}
                src={Assets.Images.Unimity}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.swiperImage6}
                src={Assets.Images.PRODAPT}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.swiperImage7}
                src={Assets.Images.PEOL}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.swiperImage8}
                src={Assets.Images.OMNICELL}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.swiperImage9}
                src={Assets.Images.Zenith}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.swiperImage10}
                src={Assets.Images.Bornfire}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.swiperImage11}
                src={Assets.Images.RYAN}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.swiperImage12}
                src={Assets.Images.IBRIDGE}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.swiperImage13}
                src={Assets.Images.Original_Logo_edi}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.swiperImage14}
                src={Assets.Images.Nityo}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.swiperImage15}
                src={Assets.Images.Knomadix}
                alt="ComponentDrop1"
              />
            </div>

            <div className={styles.slide}>
              <img
                className={styles.swiperImageURA}
                src={Assets.Images.URA}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.swiperImageUganda}
                src={Assets.Images.MLHUD}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.swiperImageTNASDC}
                src={Assets.Images.TNASDC}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.swiperImageNanMudhalvan}
                src={Assets.Images.NanMudhalvan}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.swiperImageVimarshi}
                src={Assets.Images.Vimarshi}
                alt="ComponentDrop1"
              />
            </div>
          </div>
        </section>
        {/* <Container>
        <Swiper
         
          modules={[Navigation, Autoplay]}
          autoplay={{
            delay: 1000,
            disableOnInteraction: true,
          }}
          loop="true"
          spaceBetween={10}
          slidesPerView={5}
          style={{ width: "100%", marginTop: "1.5rem", display: 'flex', alignItems: 'center' }}
          breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
        >
       
          <SwiperSlide className={styles.paginationSwip}>
            <img
              src={Assets.Images.Aezion}
              alt="ComponentDrop1"
              className={styles.swiperImage0}
            />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip}>
            <img
              src={Assets.Images.world_vision}
              alt="ComponentDrop1"
              className={styles.swiperImage1}
            />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip}>
            <img
              src={Assets.Images.ELINASERVICE}
              alt="ComponentDrop1"
              className={styles.swiperImage2}
            />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip}>
            <img
              src={Assets.Images.Girmiti}
              alt="ComponentDrop1"
              className={styles.swiperImage3}
            />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip}>
            <img
              src={Assets.Images.paripoorna}
              alt="ComponentDrop1"
              className={styles.swiperImage4}
            />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip}>
            <img
              src={Assets.Images.Unimity}
              alt="ComponentDrop1"
              className={styles.swiperImage5}
            />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip}>
            <img
              src={Assets.Images.PRODAPT}
              alt="ComponentDrop1"
              className={styles.swiperImage6}
            />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip}>
            <img
              src={Assets.Images.PEOL}
              alt="ComponentDrop1"
              className={styles.swiperImage7}
            />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip}>
            <img
              src={Assets.Images.OMNICELL}
              alt="ComponentDrop1"
              className={styles.swiperImage8}
            />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip}>
            <img
              src={Assets.Images.Zenith}
              alt="ComponentDrop1"
              className={styles.swiperImage9}
            />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip}>
            <img
              src={Assets.Images.Bornfire}
              alt="ComponentDrop1"
              className={styles.swiperImage10}
            />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip}>
            <img
              src={Assets.Images.RYAN}
              alt="ComponentDrop1"
              className={styles.swiperImage11}
            />
          </SwiperSlide>
         
          <SwiperSlide className={styles.paginationSwip}>
            <img
              src={Assets.Images.IBRIDGE}
              alt="ComponentDrop1"
              className={styles.swiperImage12}
            />
          </SwiperSlide>
        
          <SwiperSlide className={styles.paginationSwip}>
            <img
              src={Assets.Images.Original_Logo_edi}
              alt="ComponentDrop1"
              className={styles.swiperImage13}
            />
          </SwiperSlide>
        
          <SwiperSlide className={styles.paginationSwip}>
            <img
              src={Assets.Images.Nityo}
              alt="ComponentDrop1"
              className={styles.swiperImage14}
            />
          </SwiperSlide>
          
          <SwiperSlide className={styles.paginationSwip}>
            <img
              src={Assets.Images.Knomadix}
              alt="ComponentDrop1"
              className={styles.swiperImage15}
            />
          </SwiperSlide>
        </Swiper>
        </Container> */}

        <div>
          <br />
          <h6 className={styles.Clients}>Tech Stack</h6>
        </div>
        <br />

        <section className={styles.warpper}>
          <div className={styles.trackTechStack}>
            <div className={styles.slide}>
              <img
                className={styles.swiperImage16}
                src={Assets.Images.React_Iamge}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.jj}
                src={Assets.Images.PHP}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.jj}
                src={Assets.Images.Laravel}
                alt="ComponentDrop1"
              />
            </div>
            <div className={styles.slide}>
              <img
                src={Assets.Images.Nodejs}
                alt="ComponentDrop1"
                className={styles.jj}
              />
            </div>
            <div className={styles.slide}>
              <img
                src={Assets.Images.net}
                alt="ComponentDrop1"
                className={styles.jj}
              />
            </div>
            <div className={styles.slide}>
              <img
                src={Assets.Images.Native}
                alt="ComponentDrop1"
                className={styles.jj}
              />
            </div>
            <div className={styles.slide}>
              <img
                src={Assets.Images.Angular}
                alt="ComponentDrop1"
                className={styles.jj}
              />
            </div>
            {/* <div className={styles.slide}>
            <img
              src={Assets.Images.Drupal}
              alt="ComponentDrop1"
              className={styles.swiperImage15}
            />
            </div> */}
            <div className={styles.slide}>
              <img
                src={Assets.Images.android}
                alt="ComponentDrop1"
                className={styles.jj}
              />
            </div>
            <div className={styles.slide}>
              <img
                src={Assets.Images.aws}
                alt="ComponentDrop1"
                className={styles.swiperImage17}
              />
            </div>
            {/* <div className={styles.slide}>
            <img
              src={Assets.Images.md}
              alt="ComponentDrop1"
              className={styles.swiperImage17}
            />
            </div> */}
            {/* <div className={styles.slide}>
            <img
              src={Assets.Images.cyberarkpas}
              alt="ComponentDrop1"
              className={styles.swiperImage18}
            />
            </div> */}
            {/* <div className={styles.slide}>
            <img
              src={Assets.Images.ios}
              alt="ComponentDrop1"
              className={styles.swiperImage19}
            />
            </div> */}
            <div className={styles.slide}>
              <img
                src={Assets.Images.ml}
                alt="ComponentDrop1"
                className={styles.swiperImage19}
              />
            </div>
            <div className={styles.slide}>
              <img
                className={styles.swiperImage18}
                src={Assets.Images.WordPress}
                alt="ComponentDrop1"
              />
            </div>
            {/* <div className={styles.slide}>
            <img  className={styles.swiperImage19}
              src={Assets.Images.mulesoft}
              alt="ComponentDrop1"
             
            />
            </div> */}
            <div className={styles.slide}>
              <img
                src={Assets.Images.oracle}
                alt="ComponentDrop1"
                className={styles.swiperImage20}
              />
            </div>
            {/* <div className={styles.slide}>
            <img
              src={Assets.Images.kony}
              alt="ComponentDrop1"
              className={styles.jj}
            />
            </div> */}
            <div className={styles.slide}>
              <img
                src={Assets.Images.postgre}
                alt="ComponentDrop1"
                className={styles.swiperImage21}
              />
            </div>
            {/* <div className={styles.slide}>
            <img
              src={Assets.Images.pega}
              alt="ComponentDrop1"
              className={styles.swiperImage21}
            />
            </div> */}
            <div className={styles.slide}>
              <img
                src={Assets.Images.pentaho}
                alt="ComponentDrop1"
                className={styles.swiperImage17}
              />
            </div>
            <div className={styles.slide}>
              <img
                src={Assets.Images.worksoft}
                alt="ComponentDrop1"
                className={styles.jj}
              />
            </div>
            <div className={styles.slide}>
              <img
                src={Assets.Images.sap}
                alt="ComponentDrop1"
                className={styles.jj}
              />
            </div>
          </div>
        </section>

        <div className={styles.Membership}>
          <div className={styles.MembershipHeader}>
            <p>Membership and Awards</p>
            <div className={styles.NasscomAndNSRHeader}>
              <img
                data-aos="fade-down"
                data-aos-duration="1000"
                src={Assets.Images.Nasscom}
                className={styles.Nascom}
                alt="ComponentDrop1"
              />
              <img
                data-aos="fade-up"
                data-aos-duration="1000"
                src={Assets.Images.NSR}
                className={styles.Nascom}
                alt="ComponentDrop1"
              />
            </div>
          </div>
        </div>
        {/* <Container>
        <Swiper
          modules={[Navigation, Autoplay]}
          autoplay={{
            delay: 1000,
            disableOnInteraction: true,

          }}
          loop="true"
          spaceBetween={10}
          slidesPerView={5}
          className={styles.mySwiper}
          style={{ width: "100%",height: "19rem" , marginTop: "5.5rem", display: 'flex', alignItems: 'center' }}
         
           breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
        >
        
          <SwiperSlide className={styles.paginationSwip2}>
            <img
              src={Assets.Images.React_Iamge}
              alt="ComponentDrop1"
              className={styles.swiperImage}
            />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip2}>
            <img
              src={Assets.Images.PHP}
              alt="ComponentDrop1"
              className={styles.swiperImage}
            />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip2}>
            <img src={Assets.Images.Laravel}
              alt="ComponentDrop1" className={styles.swiperImage} />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip2}>
            <img src={Assets.Images.Nodejs}
              alt="ComponentDrop1" className={styles.swiperImage} />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip2}>
            <img src={Assets.Images.net}
              alt="ComponentDrop1" className={styles.swiperImage} />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip2}>
            <img src={Assets.Images.Native}
              alt="ComponentDrop1" className={styles.swiperImage} />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip2}>
            <img src={Assets.Images.Angular}
              alt="ComponentDrop1" className={styles.swiperImage} />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip1}>
            <img src={Assets.Images.Drupal}
              alt="ComponentDrop1" className={styles.swiperImage} />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip1}>
            <img src={Assets.Images.android}
              alt="ComponentDrop1" className={styles.swiperImage} />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip1}>
            <img src={Assets.Images.aws}
              alt="ComponentDrop1" className={styles.swiperImage} />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip2}>
            <img src={Assets.Images.md}
              alt="ComponentDrop1" className={styles.swiperImage} />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip2}>
            <img src={Assets.Images.cyberarkpas}
              alt="ComponentDrop1" className={styles.swiperImage} />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip2}>
            <img src={Assets.Images.ios}
              alt="ComponentDrop1" className={styles.swiperImage} />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip1}>
            <img src={Assets.Images.ml}
              alt="ComponentDrop1" className={styles.swiperImage} />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip2}>
            <img src={Assets.Images.WordPress}
              alt="ComponentDrop1" className={styles.swiperImage} />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip1}>
            <img src={Assets.Images.mulesoft}
              alt="ComponentDrop1" className={styles.swiperImage} />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip1}>
            <img src={Assets.Images.oracle}
              alt="ComponentDrop1" className={styles.swiperImage} />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip1}>
            <img src={Assets.Images.kony}
              alt="ComponentDrop1" className={styles.swiperImage} />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip2}>
            <img src={Assets.Images.postgre}
              alt="ComponentDrop1" className={styles.swiperImage} />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip3}>
            <img src={Assets.Images.pega}
              alt="ComponentDrop1" className={styles.swiperImage} />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip2}>
            <img src={Assets.Images.pentaho}
              alt="ComponentDrop1" className={styles.swiperImage} />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip1}>
            <img src={Assets.Images.worksoft}
              alt="ComponentDrop1" className={styles.swiperImage} />
          </SwiperSlide>
          <SwiperSlide className={styles.paginationSwip1}>
            <img src={Assets.Images.sap}
              alt="ComponentDrop1" className={styles.swiperImage} />
          </SwiperSlide> 
        </Swiper>
        
        
      </Container> */}
      </Container>
    </div>
  );
};
export default Clients;
