import { MdKeyboardArrowUp } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import styles from "./styles.module.css";
import { useState } from "react";

export default function Accordion2() {
  const [toggle, setToggle] = useState(1);
  return (
    <div className={styles.accordion}>
      <div
        className={
          toggle === 1 ? styles.accordionHeaderActive : styles.accordionHeader
        }
        onClick={
          toggle !== 1
            ? () => {
                setToggle(1);
              }
            : () => {
                setToggle(0);
              }
        }
      >
        <span>
          <MdKeyboardArrowDown className={toggle === 1 && "d-none"} />
          <MdKeyboardArrowUp className={toggle !== 1 && "d-none"} />
        </span>
        <span>Overview</span>
      </div>
      <div
        className={toggle !== 1 ? styles.collapseAnim : styles.accordCollapse}
      >
        Elina Services, an organization passionately dedicated to nurturing
        neurodiversity and empowering families by embracing conditions like
        Autism, ADHD, Learning Disabilities, and Intellectual Disability, sought
        to streamline its marketing and sales processes and improve outreach to
        parents and children through various channels. To achieve this, TTIPL
        (Talent Takeaways InfoTech PVT LTD.), an IT solutions provider, proposed
        the implementation of a Unified Marketing & Sales Automation Platform.
        Elina's Profile delves into the process and outcomes of integrating Zoho
        CRM, Zoho Marketing Automation, Zoho Social, and Elina's website
      </div>
      <div
        className={
          toggle === 2 ? styles.accordionHeaderActive : styles.accordionHeader
        }
        onClick={
          toggle !== 2
            ? () => {
                setToggle(2);
              }
            : () => {
                setToggle(0);
              }
        }
      >
        <span>
          <MdKeyboardArrowDown className={toggle === 2 && "d-none"} />
          <MdKeyboardArrowUp className={toggle !== 2 && "d-none"} />
        </span>

        <span>Solution Proposed By TTIPL</span>
      </div>

      <div
        className={toggle !== 2 ? styles.collapseAnim : styles.accordCollapse}
      >
        TTIPL recommended the integration of Zoho CRM, Zoho Marketing/Sales
        Automation, Zoho Social, and Elina's website to create a Unified
        Marketing & Sales Automation Platform. This platform aimed to centralize
        customer data, automate marketing /Sales workflows, and enhance
        engagement across multiple touchpoints.
      </div>
      <div
        className={
          toggle === 3 ? styles.accordionHeader1Active : styles.accordionHeader1
        }
        onClick={
          toggle !== 3
            ? () => {
                setToggle(3);
              }
            : () => {
                setToggle(0);
              }
        }
      >
        <span>
          <MdKeyboardArrowDown className={toggle === 3 && "d-none"} />
          <MdKeyboardArrowUp className={toggle !== 3 && "d-none"} />
        </span>

        <span>Outcomes and Benefits</span>
      </div>
      <div
        className={toggle !== 3 ? styles.collapseAnim : styles.accordCollapse}
      >
        By partnering with TTIPL and implementing a Unified Marketing & Sales
        Automation Platform using Zoho CRM, Zoho Marketing Automation, Zoho
        Social, and integration with their website, Elina's successfully
        transformed its marketing/Sales operations. The streamlined processes,
        centralized data management, and enhanced customer engagement
        capabilities have positioned Elina's for continued growth and success in
        the competitive fashion retail industry
      </div>
    </div>
  );
}
