import React, { useState, useEffect } from "react";
import Assets from "../../Config/Assets";
import styles from "./styles.module.css";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CareerModal from "../../Commpoents/Modal/CareerModal/index.js";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Bolt } from "@mui/icons-material";
import Development from "../Devlopment/index";
import Aos from "aos";
import "aos/dist/aos.css";

const Career = () => {
  const [index, setIndex] = useState(0);

  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });

  return (
    <div>
      {/* <div className={styles.backgroung}></div> */}
      <div className={styles.MainScroll}>
        <div className={styles.MainHeader}>
          <div className={styles.careerlandimage}>
            {/* <img alt="Sample" src={Assets.Images.careerlanding_img} /> */}
            <Container className={styles.Headertext}>
              <Row>
                <Col>
                  <div className={styles.LandingPageContent}>
                    {/* <p>Career</p> */}
                  </div>
                  <div>
                    <h6
                      data-aos="fade-down"
                      className={styles.LandingPageContent2}
                    >
                      Welcome To Our Career Portal!
                    </h6>
                    <br />
                    <h4
                      data-aos="fade-down"
                      className={styles.LandingPageContent1}
                    >
                      We look forward to your participation
                    </h4>
                  </div>
                  <br />
                  <br />
                  <div className={styles.samp}>
                    <img
                      data-aos="fade-down"
                      alt="Sample"
                      id={styles.sample}
                      src={Assets.Images.down}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <div className="container" id={styles.CareerMainheader}>
          <div className="row">
            <div
              className="col-lg-6"
              style={{ display: "flex", alignItems: "start" }}
            >
              <div className={styles.Culture_Contenthead}>
                <span className={styles.Culture_Content}>
                  Joining Talent Takeaways gives You the exposure to face meet
                  challenges with{" "}
                  <p>
                    {" "}
                    Innovation, Dedication, <span>and</span> Passion.
                  </p>
                </span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className={styles.text}>
                <p className={styles.text1}>
                  Our development team is looking for talented and
                  mission-driven youth to join our team of visionary experts to
                  build cutting-edge products.
                </p>
                <p>
                  At Talent Takeaways, you’ll have the opportunity to get
                  creative on a vast array of projects, big and small, expand
                  your skills, and be part of a positive and collaborative
                  company culture.
                </p>
              </div>
              <div className={styles.sys}>
                <button onClick={handleClick} className={styles.btn6}>
                  Apply Now!
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.perks}>
          <div className="container" id={styles.perkshead}>
            <div className="row">
              <div className="col-lg-6">
                <div>
                  <div className={styles.CareerPerks}>
                    <p>Perks & Benefits</p>
                  </div>

                  <div className={styles.Employeeimg}>
                    <img alt="Sample" src={Assets.Images.Employee_Benefits} />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className={styles.Competitivecareerhead}>
                  <div className={styles.Competitivecareer}>
                    <div className={styles.careertickconetent}>
                      <img alt="Sample" src={Assets.Images.careertick} />

                      <span className={styles.careertick}>
                        Competitive salary and benefits.
                      </span>
                    </div>
                    <div className={styles.careertickconetent}>
                      <img alt="Sample" src={Assets.Images.careertick} />
                      <span className={styles.careertick}>
                        Fund support for professional development opportunities
                        and courses.
                      </span>
                    </div>
                    {/* <div className={styles.careertickconetent}>
                  <img alt="Sample" src={Assets.Images.careertick} />
                  <span className={styles.careertick}>Travel allowance</span>
                </div> */}
                    <div className={styles.careertickconetent}>
                      <img alt="Sample" src={Assets.Images.careertick} />
                      <span className={styles.careertick}>
                        On the Job Professional Training.
                      </span>
                    </div>
                    <div className={styles.careertickconetent}>
                      <img alt="Sample" src={Assets.Images.careertick} />
                      <span className={styles.careertick}>
                        A remote-friendly schedule that allows employees to work
                        from home either part-time or as much as they reasonably
                        ask for.
                      </span>
                    </div>
                    <div className={styles.careertickconetent}>
                      <img alt="Sample" src={Assets.Images.careertick} />
                      <span className={styles.careertick}>
                        Benefits to improve work-life balance.
                      </span>
                    </div>
                    <div className={styles.careertickconetent}>
                      <img alt="Sample" src={Assets.Images.careertick} />
                      <span className={styles.careertick}>
                        An employer who truly values personal development and
                        growth
                        <p>... and who invites you to reciprocate the same!</p>
                      </span>
                    </div>

                    <div className={styles.careertickconetent}>
                      <img alt="Sample" src={Assets.Images.careertick} />
                      <span className={styles.careertick}>
                        Room for new initiatives and ideas.
                        <p>
                          We’re always willing to listen, whether you’ve been
                          with us for a week, a month, or a year.
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container" id={styles.CareerMainheader}>
          <div className="row">
            <div className="col-lg-5">
              <div className={styles.Corecontent}>
                <h3>Our Cultural Values</h3>
                <p>
                  We fully understand and appreciate the value of diversity, and
                  we are committed to creating an inclusive environment for all
                  employees. We are proud to be an equal-opportunity workplace.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-7">
              <div>
                <Carousel activeIndex={index} onSelect={handleSelect}>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      id={styles.carouselimg}
                      src={Assets.Images.CarouselImage1}
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      id={styles.carouselimg}
                      className="d-block w-100"
                      src={Assets.Images.CarouselImage2}
                      alt="Second slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      id={styles.carouselimg}
                      className="d-block w-100"
                      src={Assets.Images.CarouselImage3}
                      alt="Third slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      id={styles.carouselimg}
                      className="d-block w-100"
                      src={Assets.Images.CarouselImage4}
                      alt="Fourth slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      id={styles.carouselimg}
                      className="d-block w-100"
                      src={Assets.Images.CarouselImage5}
                      alt="Six slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      id={styles.carouselimg}
                      className="d-block w-100"
                      src={Assets.Images.CarouselImage6}
                      alt="Six slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      id={styles.carouselimg}
                      className="d-block w-100"
                      src={Assets.Images.CarouselImage7}
                      alt="Seven slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      id={styles.carouselimg}
                      className="d-block w-100"
                      src={Assets.Images.CarouselImage8}
                      alt="Eight slide"
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid" id={styles.Development5}>
          <div className={styles.Developmentheader}>
            <h3>Interested in becoming part of the team?</h3>
            <h3>We may be just right for each other!</h3>
          </div>
          <div
            className="row flex-column-reverse flex-sm-row"
            id={styles.techcard}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              className="col-lg-6"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <div className={styles.Designerscontenthead5}>
                <div className={styles.Designerscontent5}>
                  <h3>DevOps Engineers</h3>
                </div>
                <div className={styles.Designerscontent5}>
                  <p>
                    We are looking for software developers who are experts in
                    DevOps automation and have experience working in
                    cross-functional teams. We might just be made for each
                    other. Our DevOps engineers work with cloud services such as
                    AWS and Google Cloud, use Docker and Kubernetes, and secure
                    successful product launches and lifecycles for our products.
                  </p>
                </div>

                <div className={styles.sys}>
                  <button onClick={handleClick} className={styles.btn5}>
                    Apply Now!
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className={styles.CareerCardImage5}>
                <img alt="Sample" src={Assets.Images.devops} />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid" id={styles.Development4}>
          <div
            className="row"
            id={styles.techcard}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className={styles.CareerCardImage4}>
                <img alt="Sample" src={Assets.Images.qa} />
              </div>
            </div>

            <div
              className="col-lg-6"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <div className={styles.Designerscontent4}>
                <h3>QA Specialists</h3>
              </div>
              <div className={styles.Designerscontent4}>
                <p>
                  Are you a QA engineer with experience in requirement analysis,
                  test case creation, and bug tracking? Let us meet. You will
                  assist our skilled technical team in ensuring the quality of
                  our 360-degree product cycle for mobile and web application
                  experiences, if possible, through QA automation.
                </p>
              </div>
              <div className={styles.sys}>
                <button onClick={handleClick} className={styles.btn}>
                  Apply Now!
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid" id={styles.Development1}>
          <div
            class="row flex-column-reverse flex-sm-row"
            id={styles.techcard}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className={styles.Designerscontent1}>
                <h3>Full-Stack Developers</h3>
              </div>
              <div className={styles.Designerscontent1}>
                <p>
                  We need passionate developers who have a passion for
                  experimenting with new and trending technologies and who like
                  to innovate with classic languages. Our main stacks include
                  React JS, Angular JS, and Vue JS for the front end and Node
                  JS, Golang, PHP, and Ruby on Rails for the back end. But we
                  continuously expand our capabilities.
                </p>
              </div>

              <div className={styles.sys}>
                <button onClick={handleClick} className={styles.btn1}>
                  Apply Now!
                </button>
              </div>
            </div>

            <div
              className="col-lg-6"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <div className={styles.CareerCardImage1}>
                <img alt="Sample" src={Assets.Images.bg_full_stack} />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid" id={styles.Development}>
          <div
            className="row"
            id={styles.techcard}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              className="col-lg-6"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <div className={styles.CareerCardImage}>
                <img alt="Sample" src={Assets.Images.bg_mobile} />
              </div>
            </div>

            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className={styles.Designerscontent}>
                <h3>Mobile App Developers</h3>
              </div>
              <div className={styles.Designerscontent}>
                <p>
                  Are you willing to join one of the best mobile development
                  teams? If you’re passionate about building high-quality,
                  user-centered apps, Talentakeaways is the right place for you.
                  We work and code in Swift, Objective-C, Java, Kotlin, and
                  hybrid platforms such as React Native.
                </p>
              </div>

              <div className={styles.sys}>
                <button onClick={handleClick} className={styles.btn}>
                  Apply Now!
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid" id={styles.Development3}>
          <div
            class="row flex-column-reverse flex-sm-row"
            id={styles.techcard}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className={styles.Designerscontent3}>
                <h3>UX/UI Designers</h3>
              </div>
              <div className={styles.Designerscontent3}>
                <p>
                  We are looking for talented designers with a positive attitude
                  and who are self-motivated to join our dream team of UX/UI
                  designers! Your job will be to design state-of-the-art apps
                  and user interfaces and convert ideas and concepts into
                  inspiring digital master pieces.
                </p>
              </div>
              <div className={styles.sys}>
                <button onClick={handleClick} className={styles.btn3}>
                  Apply Now!
                </button>
              </div>
            </div>

            <div
              className="col-lg-6 "
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <div className={styles.CareerCardImage3}>
                <img alt="Sample" src={Assets.Images.bg_designer} />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container-fluid" id={styles.Development2}>
        <div className="row" id={styles.techcard}>
          <div className="col-lg-6  ">
            <div className={styles.CareerCardImage2}>
              <img alt="Sample" src={Assets.Images.ux_research} />
            </div>
          </div>

          <div className="col-lg-6">
            <div className={styles.Designerscontent2}>
              <h3></h3>
            </div>
            <div className={styles.Designerscontent2}>
              <p>
               
              </p>
            </div>

            <div className={styles.sys}>
              <button className={styles.btn} onClick={handleClick}>
                Apply Now!
              </button>
            </div>
          </div>
        </div>
      </div> */}
        {/* <div className="container " id={styles.ImageHead}>
        <div class="row">
          <div class="col-lg-3 " id={styles.col1}>
            <div className={styles.Footerimhead}>
              <img src={Assets.Images.pic4} className={styles.footerimg1} />

              <img src={Assets.Images.pic2} className={styles.footerimg1} />
            </div>
          </div>
          <div class="col-lg-3" id={styles.col1}>
            <img src={Assets.Images.pic7} className={styles.footerimg2} />
          </div>

          <div class="col-lg-6 " id={styles.col1}>
            <div className={styles.footerimg}>
              <img src={Assets.Images.pic4} className={styles.footerimg4} />
              <img src={Assets.Images.pic4} className={styles.footerimg4} />
              <img src={Assets.Images.pic6} className={styles.footerimg4} />
              <img src={Assets.Images.pic1} className={styles.footerimg4} />
            </div>
          </div>
        </div>
      </div> */}

        {/* <div className="container-fluid " id={styles.ImageHead}>
        <div className="row" id={styles.gridimage}>
          <div className="col-lg-3 " id={styles.col1}>
            <div className={styles.footerimg}>
              <img src={Assets.Images.pic1} className={styles.footerimg4} />
            </div>
          </div>
          <div className="col-lg-3 " id={styles.col1}>
            <div className={styles.footerimg}>
              <img src={Assets.Images.pic2} className={styles.footerimg4} />
            </div>
          </div>
          <div className="col-lg-3 " id={styles.col1}>
            <div className={styles.footerimg}>
              <img src={Assets.Images.pic3} className={styles.footerimg4} />
            </div>
          </div>

          <div className="col-lg-3 " id={styles.col1}>
            <div className={styles.footerimg}>
              <img src={Assets.Images.pic4} className={styles.footerimg4} />
            </div>
          </div>
        </div>
      </div> */}
        <div className="container-fluid" id={styles.card}>
          <div className={styles.Applicationcontent}>
            <h3>We'd like to hear from you!</h3>
            <p>
              If the opportunities at talent takeaways excite you, we encourage
              you to apply.
            </p>
            <p>
              If you have any questions before applying, you can send an e-mail
              to
              <span id={styles.linkspara}> hr@talentakeaways.com</span>.
            </p>
            <p>
              Please send all applications through the Career Portal. We look
              forward to recieving your application!
            </p>
          </div>
        </div>

        <CareerModal open={open} handleClose={() => setOpen(false)} />
      </div>
    </div>
  );
};
export default Career;
