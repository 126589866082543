import { MdKeyboardArrowUp } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import styles from "./styles.module.css";
import { useState } from "react";

export default function Accordion() {
  const [toggle, setToggle] = useState(1);
  return (
    <div className={styles.accordion}>
      <div
        className={
          toggle === 1 ? styles.accordionHeaderActive : styles.accordionHeader
        }
        onClick={
          toggle !== 1
            ? () => {
                setToggle(1);
              }
            : () => {
                setToggle(0);
              }
        }
      >
        <span>
          <MdKeyboardArrowDown className={toggle === 1 && "d-none"} />
          <MdKeyboardArrowUp className={toggle !== 1 && "d-none"} />
        </span>
        <span>Background</span>
      </div>
      <div
        className={toggle !== 1 ? styles.collapseAnim : styles.accordCollapse}
      >
        Group MFI Document Solutions Uganda is a reputable provider of document
        management solutions catering to businesses and organizations across
        Uganda. With a focus on enhancing operational efficiency, security, and
        compliance, Group MFI Document Solutions Uganda offers a comprehensive
        range of services designed to streamline document workflows and improve
        productivity.
      </div>
      <div
        className={
          toggle === 2 ? styles.accordionHeaderActive : styles.accordionHeader
        }
        onClick={
          toggle !== 2
            ? () => {
                setToggle(2);
              }
            : () => {
                setToggle(0);
              }
        }
      >
        <span>
          <MdKeyboardArrowDown className={toggle === 2 && "d-none"} />
          <MdKeyboardArrowUp className={toggle !== 2 && "d-none"} />
        </span>

        <span>Challenges Faced by MFI Solutions</span>
      </div>

      <div
        className={toggle !== 2 ? styles.collapseAnim : styles.accordCollapse}
      >
        Limited Market Visibility: Despite offering innovative solutions, Group
        MFI Document Solutions Uganda faces challenges in effectively reaching
        and engaging its target audience, resulting in limited market visibility
        and growth opportunities. Manual Sales Processes: Manual sales processes
        and fragmented data management hinder sales team productivity and
        inhibit the ability to track and manage customer interactions
        effectively. Marketing Inefficiencies: Group MFI Document Solutions
        Uganda lacks a cohesive marketing strategy and struggles to execute
        targeted marketing campaigns, resulting in suboptimal lead generation
        and conversion rates
      </div>
      <div
        className={
          toggle === 3 ? styles.accordionHeader1Active : styles.accordionHeader1
        }
        onClick={
          toggle !== 3
            ? () => {
                setToggle(3);
              }
            : () => {
                setToggle(0);
              }
        }
      >
        <span>
          <MdKeyboardArrowDown className={toggle === 3 && "d-none"} />
          <MdKeyboardArrowUp className={toggle !== 3 && "d-none"} />
        </span>

        <span>Solution Proposed by TTIPL</span>
      </div>
      <div
        className={toggle !== 3 ? styles.collapseAnim : styles.accordCollapse}
      >
        To address the challenges faced by Group MFI Document Solutions Uganda,
        TTIPL proposed the implementation of a Unified Marketing and Sales
        Automation Platform. This solution integrates Zoho CRM, Zoho
        Marketing/Sales Automation, Zoho Social, and Group MFI Document
        Solutions Uganda's website to streamline operations, enhance customer
        engagement, and drive business growth.
      </div>
    </div>
  );
}
