import { Col, Container } from "reactstrap";
import SolutionCard from "../../Commpoents/Zoho/SolutionCard";
import { Row } from "react-bootstrap";

import styles from "./styles.module.css";

import calander from "../../Images/ZohoPng/9.png";
import clock from "../../Images/ZohoPng/10.png";
import idea from "../../Images/ZohoPng/11.png";
import note from "../../Images/ZohoPng/12.png";
import puzzle from "../../Images/ZohoPng/13.png";
import person from "../../Images/ZohoPng/14.png";
import setting from "../../Images/ZohoPng/15.png";
import handshake from "../../Images/ZohoPng/16.png";
import communication from "../../Images/ZohoPng/17.png";
import correct from "../../Images/ZohoPng/18.png";
import bgVector from "../../Images/ZohoPng/24.png";
import consult from "../../Images/ZohoPng/1.png";
import analysis from "../../Images/ZohoPng/2.png";
import customize from "../../Images/ZohoPng/22.png";
import implement from "../../Images/ZohoPng/20.png";
import integration from "../../Images/ZohoPng/21.png";
import migration from "../../Images/ZohoPng/5.png";

import slider1 from "../../Images/ZohoPng/slider11.png";
import slider2 from "../../Images/ZohoPng/slider22.png";

import slider3 from "../../Images/ZohoPng/slider33.png";
// import hand from "../../Images/ZohoPng/23.png";
// import create from "../../Images/ZohoPng/create.png";
// import dream from "../../Images/ZohoPng/dream.png";
import mobile from "../../Images/ZohoPng/mobile.png";

import desktop from "../../Images/ZohoPng/stepswe.png";

import Mission from "../../Commpoents/Zoho/Mission";
// import ImageContent from "../../Commpoents/Zoho/ImageContent";
import { useEffect, useState } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

import ZohoImplement from "../../Images/ZohoPng/Company-cuate.svg";

import ZohoTTIPL from "../../Images/TTIPLZOHO2.png";

import { Helmet } from "react-helmet";

import InspireCreateDream from "../../Commpoents/Carousel/InspireCreateDream";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const SOLUTIONS = [
  {
    src: calander,
    heading: "Availability",
    content:
      "Our Developers are available 24x7 ensuring that any issues or requirements are addressed promptly. Whether it’s during office hours or beyond, we’re here to provide support whenever you need it.",
    lg: "4",
    sm: "5",
  },
  {
    src: clock,
    heading: "Responsiveness",
    content:
      "We are responsive towards our service delivery. With the best problem-solving capabilities, we ensure that our solutions always meet your business needs effectively.",
    lg: "4",
    sm: "5",
  },
  {
    src: idea,
    heading: "Focused Solution",
    content:
      "We focus on providing Zoho CRM solution of your problem rather than selling our services. We ensure our consultation is Valuable.",
    lg: "4",
    sm: "5",
  },
  {
    src: note,
    heading: "Cost Effectiveness",
    content:
      "We offer Cost-Effective Solutions that help maximize the value of your investment. Also, we have a clear understanding of the costs involved & can make informed decisions about whether to use your services or not.",
    lg: "4",
    sm: "5",
  },
  {
    src: puzzle,
    heading: "Power of Team",
    content:
      "Our Team's diverse skills & expertise in all Zoho apps can bring a range of perspectives to problem-solving & effective solutions for your business.  ",
    lg: "4",
    sm: "5",
  },
  {
    src: person,
    heading: "Account Manager",
    content:
      "Dedicated person to ensures efficient & effective execution of every project, seamless communication & tracking of progress towards project goals.",
    lg: "4",
    sm: "5",
  },
  {
    src: setting,
    heading: "Systematic Project Management",
    content:
      "We offer Cost-Effective Solutions that help maximize the value of your investment. Also, we have a clear understanding of the costs involved & can make informed decisions about whether to use your services or not.",
    lg: "4",
    sm: "5",
  },
  {
    src: handshake,
    heading: "No False Promise under Delivery",
    content:
      "We offer Cost-Effective Solutions that help maximize the value of your investment. Also, we have a clear understanding of the costs involved & can make informed decisions about whether to use your services or not.",
    lg: "4",
    sm: "5",
  },
  {
    src: communication,
    heading: "Communication",
    content:
      "Clear and concise communication is a key to our service delivery. Our developers work closely to understand your requirements, provide regular updates, and seek feedback to ensure the results align with your vision.",
    lg: "6",
    sm: "5",
  },
  {
    src: correct,
    heading: "Honest Consultancy",
    content:
      "Provide honest advice, recommendations and consulting services to our clients based on needs & goals, rather than seeking our own interests so that the client is aware of any limitations or any constraints.",
    lg: "6",
    sm: "5",
  },
];

const MISSONS = [
  {
    src: consult,
    heading: "Consultation",
    content:
      "Analyze your needs and provide Zoho Consulation solution assessment, platform recommendation and roadmap Planning.",
  },
  {
    src: analysis,
    heading: "Gap Analysis",
    content:
      "Every business aspires to get transformational improvement results by changing the existing operating structures.",
  },
  {
    src: customize,
    heading: "Implementation",
    content:
      "Implement the Solution as per Business needs and automate process for Smooth functioning.",
  },
  {
    src: implement,
    heading: "Customization",
    content:
      "We modify the platforms as per the need, custom functions that help you to automate complex business processes out of the box.",
  },
  {
    src: integration,
    heading: "Integration",
    content:
      "We make easy to use multiple internal and 3rd party applications work together to give your business a cutting edge effect with our Integration expertise.",
  },
  {
    src: migration,
    heading: "Data Migration",
    content:
      "Migration to New System requires time and skills to knowledge of different aspects, we have got you covered with Master data migration and transaction level migration.",
  },
];

export default function Zoho() {
  const { innerWidth } = window;
  const [step, setStep] = useState(0);
  const [active, setActive] = useState(1);

  let length = 3;

  useEffect(() => {
    const time = setInterval(() => {
      if (active < length) setActive(active + 1);
      else setActive(1);
    }, 5000);
    return () => clearTimeout(time);
  }, [active, length]);

  const next = (e) => {
    e.preventDefault();
    if (active < length) setActive(active + 1);
    else setActive(1);
  };

  const prev = (e) => {
    e.preventDefault();

    if (active === 1) {
      setActive(length);
    } else {
      setActive(active - 1);
    }
  };

  return (
    <>
      <Helmet>
        <title>Zoho Implementation</title>
        <meta
          name="description"
          content="Elevate your business with expert Zoho implementation. Our tailored solutions optimize CRM, finance, and more for seamless operations. Drive growth and efficiency with our proven expertise. Contact us now."
        />

        <meta property="og:title" content="Zoho Implementation" />
        <meta
          property="og:description"
          content="Elevate your business with expert Zoho implementation. Our tailored solutions optimize CRM, finance, and more for seamless operations. Drive growth and efficiency with our proven expertise. Contact us now."
        />
        <meta property="og:image" content={ZohoTTIPL} />
        <meta
          property="og:url"
          content="https://www.talentakeaways.com/zoho-implementation"
        />
        <meta property="og:type" content="website" />

        <meta name="twitter:title" content="Zoho Implementation" />
        <meta
          name="twitter:description"
          content="Elevate your business with expert Zoho implementation. Our tailored solutions optimize CRM, finance, and more for seamless operations. Drive growth and efficiency with our proven expertise. Contact us now."
        />
        <meta name="twitter:image" content={ZohoTTIPL} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Container fluid className=" overflow-hidden p-0">
        <section>
          <Row className={styles.heroSection}>
            <Col className={styles.heroCol1} md="6">
              <h1>Zoho Implementation Partner</h1>
              <h4>Providing Proficient Zoho Setup and Implementation</h4>
              <p>
                {" "}
                As Zoho implementation partner, we have the best Zoho
                Development team and worked on -{" "}
                <span>implementation, development, and integration</span>{" "}
                projects of various complexities for{" "}
                <span>different industrial sectors.</span>
              </p>
            </Col>
            <Col md="6">
              <img src={ZohoImplement} alt="zohoVector" width="80%" />
            </Col>
          </Row>
          <Row className="mt-5 mx-4 ">
            <Col sm="12" className="position-relative overflow-hidden  px-5">
              <img
                src={slider3}
                alt="fullImage"
                width="100%"
                className={active === 1 ? styles.fadeIn : "d-none"}
              />

              <img
                src={slider2}
                alt="fullImage"
                width="100%"
                className={active === 2 ? styles.fadeIn : "d-none"}
              />

              <img
                src={slider1}
                alt="fullImage"
                width="100%"
                className={active === 3 ? styles.fadeIn : "d-none"}
              />

              <button className={styles.left} onClick={prev}>
                <HiChevronLeft />
              </button>
              <button className={styles.right} onClick={next}>
                <HiChevronRight />
              </button>
            </Col>
          </Row>
        </section>

        <section>
          <Row className={styles.vision}>
            <h2 className="h2 mb-4 text-center">
              Empower Your Business Vision:
              <p>Dream, Create, Inspire with Tailored Zoho Solutions</p>
            </h2>
            <div className={styles.toggle}>
              <h5 className={styles.inspire}>Inspire</h5>
              <FaAngleLeft
                className={styles.inspireLeft}
                onClick={() => {
                  if (step > 0) setStep(step - 1);
                }}
              />
              <div
                onClick={() => {
                  setStep(0);
                }}
                className={
                  step === 0 ? styles.roundBtnActive1 : styles.roundBtn1
                }
              ></div>
              <h5 className={styles.create}>Create</h5>
              <div
                onClick={() => {
                  setStep(1);
                }}
                className={
                  step === 1 ? styles.roundBtnActive2 : styles.roundBtn2
                }
              ></div>
              <h5 className={styles.dream}>Dream</h5>
              <div
                onClick={() => {
                  setStep(2);
                }}
                className={
                  step === 2 ? styles.roundBtnActive3 : styles.roundBtn3
                }
              ></div>
              <FaAngleRight
                className={styles.inspireRight}
                onClick={() => {
                  if (step < 2) setStep(step + 1);
                }}
              />
            </div>
          </Row>

          <Row className="justify-content-center mt-5 d-sm-none">
            <Col
              onClick={() => {
                setStep(0);
              }}
              sm="7"
            >
              <div
                className={step === 0 ? styles.stepperActive : styles.stepper}
              >
                Inspire
              </div>
            </Col>
            <Col
              onClick={() => {
                setStep(1);
              }}
              sm="7"
            >
              <div
                className={step === 1 ? styles.stepperActive : styles.stepper}
              >
                Create
              </div>
            </Col>
            <Col
              onClick={() => {
                setStep(2);
              }}
              sm="7"
            >
              <div
                className={step === 2 ? styles.stepperActive : styles.stepper}
              >
                Dream
              </div>
            </Col>
          </Row>
        </section>

        <section>
          {/* <ImageContent
            src={
              (step === 0 && hand) ||
              (step === 1 && create) ||
              (step === 2 && dream)
            }
          >
            {step === 0 && (
              <ol className="h5">
                <li>Operates on auto-pilot to make your journey smoother</li>
                <li>Get Rid of Excel sheets</li>
                <li> Proper governance and reporting/dashboard</li>
                <li>Easy Forecast</li>
                <li>
                  Automated customer management that empowers your business.
                </li>
                <li> Deliver sustainable resolts to you in the long term.</li>
                <li>Consolidated customer data with a 360-degree view</li>
              </ol>
            )}
            {step === 1 && (
              <ol className="h5">
                <li>Effortless system that works for your business</li>
                <li>Omni channel cloud-based system</li>
                <li>Risk-free client experience</li>
                <li> Unmatched marketing efforts and client success </li>
                <li>Transparent Operations and Smooth Execution </li>
              </ol>
            )}
            {step === 2 && (
              <ol className="h5">
                <li>
                  Easy documented communication between your employees and
                  customer
                </li>
                <li>Process driven business</li>
                <li>
                  Seamless Integration with other systems in your organization
                </li>
                <li> 24×7 customer support from an experienced team </li>
              </ol>
            )}
          </ImageContent> */}
          <InspireCreateDream step={step} setStep={setStep} />
        </section>

        <section id="whatwedo">
          <Row className={styles.mission}>
            <div className={styles.missionHeader}>
              <h4 className={innerWidth > 426 ? "h4" : "h6"}>
                ZOHO SUITE OF APPS CONSULTING SERVICES
              </h4>
              <h2 className={innerWidth > 426 ? "h3" : "h5"}>
                Our Mission Is to Make Your Business Better Through Technology
              </h2>
            </div>
            {MISSONS.map((elem) => {
              return (
                <Col sm="5" lg="4" className={styles.missionMargin}>
                  <Mission
                    src={elem.src}
                    heading={elem.heading}
                    content={elem.content}
                  />
                </Col>
              );
            })}
          </Row>
        </section>

        <section>
          <Row className={styles.solutions}>
            <Col lg="4" sm="12" className={styles.colMargin}>
              <h1 className={styles.h1}>
                What Make Us Out of the Box Solution Provider !
              </h1>
            </Col>
            {SOLUTIONS.map((elem) => {
              return (
                <Col
                  className={styles.colMargin}
                  key={elem.heading}
                  sm={elem.sm}
                  lg={elem.lg}
                >
                  <SolutionCard
                    src={elem.src}
                    heading={elem.heading}
                    content={elem.content}
                  />
                </Col>
              );
            })}
            <div>
              <img src={bgVector} alt="bgVector" className={styles.bgVector} />
            </div>
          </Row>
        </section>
        <section>
          <Row className="mb-4">
            <Col>
              {innerWidth <= 426 ? (
                <>
                  <h4 className={styles.textCenter}>
                    Steps We Follow To Meet Your Success On Zoho
                  </h4>
                  <img src={mobile} alt="mobile" width="100%" />
                </>
              ) : (
                <>
                  <h1 className={styles.last}>
                    Steps We Follow To Meet Your Success On Zoho
                  </h1>
                  <img src={desktop} alt="desktop" width="100%" />
                </>
              )}
            </Col>
          </Row>
        </section>
      </Container>
    </>
  );
}
