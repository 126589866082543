import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import "./style.css";
import { Spinner } from "react-bootstrap";
import { useNavigate, usehi } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
const FooterModal = (props) => {
  const { open, handleClose } = props;
  let navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const [userName, setuserName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const handleClickbitton = () => {
    setuserName(null);
    setEmail(null);
    setPhoneNumber(null);
    setMessage(null);
    handleClose(false);
  };

  // const history = useNavigate();

  // const handlecloser = () => {
  //   handleClose(false);
  // };
  const AddContactInfo = () => {
    let nameValidation = /(^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z]$)/;
    if (userName == "" || userName == null) {
      alert("Please Enter Name");
      return false;
    } else if (!nameValidation.test(userName)) {
      alert("Please Enter Valid User Name");
      return false;
    }
    let phoneNumberValidation =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    if (phoneNumber == "" || phoneNumber == null) {
      alert("Please Enter Phone Number");
      return false;
    } else if (!phoneNumberValidation.test(phoneNumber)) {
      alert("Please Enter Valid Phone Number");
      return false;
    }
    let emailValidate = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email == "" || email == null) {
      alert("Please Enter  Email");
      return false;
    } else if (!emailValidate.test(email)) {
      alert("Please Enter Valid Email Id");
      return false;
    }

    if (message == "" || message == null) {
      alert("Please Enter Message");
      return false;
    }

    let data = {
      userName: userName,
      email: email,
      phoneNumber: phoneNumber,
      message: message,
    };
    axios
      .post("https://fia-uganda-edrms.com:3500/api/contact_form", data)
      .then((res) => {
        console.log(res, "response");
        if (res.data?.statusCode === 200) {
          alert("Your Message Has been sent Successfully.");
          handleClickbitton();
        }
      });
  };

  return (
    <>
      <Modal show={open} centered size="lg">
        <Modal.Header
          closeButton
          className="Modalbody"
          onHide={handleClickbitton}
          style={{ padding: "1.5rem", color: "#fff", left: "0px" }}
        >
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                placeholder="Enter Full Name"
                //autoFocus
                id="userName"
                value={userName}
                onChange={(e) => setuserName(e.target.value)}
                className="inputcontentpop"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="tel"
                placeholder="Enter Phone Number"
                id="phoneNumber"
                //autoFocus
                value={phoneNumber}
                className="inputcontentpop"
                onChange={(e) =>
                  e.target.value.length <= 10 && setPhoneNumber(e.target.value)
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="email"
                id="email"
                placeholder="Enter Email Address"
                // autoFocus
                value={email}
                className="inputcontentpop"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <textarea
                type="textarea"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Enter Message"
                id="message"
                className="textarea1"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            value="Submit"
            onClick={AddContactInfo}
            className="btn btn-info"
          >
            Submit
          </button>
          <Button
            className="cancelbutton"
            variant="danger"
            onClick={handleClickbitton}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default FooterModal;
