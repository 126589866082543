import React, { useRef, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import LandingMainBackground from "../Screens/landingPage/LandingMainBackground";

import AboutUsMainHeader from "../Screens/AboutUsMain/Header";
import Blogs from "../Screens/Blog/index";
import Career from "../Screens/Career";
import Cardcontent1 from "../Screens/Blog/cards";

import ReactGA from "react-ga";
import LayOut from "../Layout";

import Mainblog from "../Config/CommentData";
import Zoho from "../Screens/ReadMore/Zoho";
import ZohoPage from "../Screens/ReadMore/ZohoPage";
const Routing = () => {
  const develop = useRef(null);
  const services = useRef(null);
  const Contact = useRef(null);
  const navbarscrollMain = useRef(null);
  const howwedo = useRef(null);
  const ourClients = useRef(null);

  const scrollChange = (element) => {};

  useEffect(() => {
    if (Mainblog) {
      localStorage.setItem("items", JSON.stringify(Mainblog));
    }
  }, []);

  useEffect(() => {
    ReactGA.initialize("UA-255219020-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <LayOut
              scrollChange={scrollChange}
              services={services}
              develop={develop}
              Contact={Contact}
              navbarscrollMain={navbarscrollMain}
              howwedo={howwedo}
              ourClients={ourClients}
            />
          }
        >
          <Route path="/about" element={<AboutUsMainHeader />} />
          <Route
            path="/blog"
            element={
              <Blogs
                navbarscrollMain={navbarscrollMain}
                scroll
                Change={scrollChange}
              />
            }
          />

          <Route
            path="/"
            element={
              <LandingMainBackground
                develop={develop}
                services={services}
                howwedo={howwedo}
                ourClients={ourClients}
              />
            }
          />
          <Route path="/about" element={<AboutUsMainHeader />} />
          <Route path="/blog" element={<Blogs />} />
          <Route path="/zoho-implementation" element={<Zoho />} />
          <Route path="/zoho-marketing" element={<ZohoPage />} />

          <Route path="/blog/:content/:id" element={<Cardcontent1 />} />

          <Route path="/about" element={<AboutUsMainHeader />} />
          <Route path="/career" element={<Career />} />
          <Route
            path="/Header"
            element={
              <AboutUsMainHeader
                AboutUsMainHeader={AboutUsMainHeader}
                scrollChange={scrollChange}
              />
            }
          />
        </Route>
      </Routes>
    </Router>
  );
};
export default Routing;
