import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import Header from "./Header";
import NavbarLanding from "./Navbar";
import ZohoNavbarLanding from "./NavForZoho";
import { useLocation } from "react-router-dom";
import CommonNav from "./CommonNav";

const MainHeader = (props) => {
  const {
    scrollChange,
    develop,
    services,
    Contact,
    navbarscrollMain,
    howwedo,
    ourClients,
    Modal,
  } = props;
  const [navbar, setNavbar] = useState(false);

  const { pathname } = useLocation();

  // useEffect(() => {
  //   changebackground();
  // }, [navbar]);

  // const changebackground = () => {
  //   if (window.scrollY >= 65) {
  //     setNavbar(true);
  //   } else {
  //     setNavbar(false);
  //   }
  // };
  // window.addEventListener("scroll", changebackground);
  return (
    <header className={styles.MainHeader1}>
      <Header
        navbar={navbar}
        develop={develop}
        // scrollChange={scrollChange}
        Contact={Contact}
      />
      {pathname.split("/")[1] !== "zoho-implementation" &&
      pathname.split("/")[1] !== "zoho-marketing" ? (
        <NavbarLanding
        // navbar={navbar}
        // scrollChange={scrollChange}
        // services={services}
        // develop={develop}
        // Contact={Contact}
        // navbarscrollMain={navbarscrollMain}
        // howwedo={howwedo}
        // ourClients={ourClients}
        // Modal={Modal}
        />
      ) : pathname.split("/")[1] !== "zoho-marketing" ? (
        <ZohoNavbarLanding />
      ) : (
        <CommonNav />
      )}
    </header>
  );
};
export default MainHeader;
