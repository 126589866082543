import { Container, Row, Col } from "reactstrap";

import styles from "./styles.module.css";

export default function Clients({
  src,
  children,
  header,
  subHeader,
  className,
}) {
  return (
    <Container fluid className={className}>
      <h1 className={styles.clientHeader}>{header}</h1>
      <h5 className={styles.clientSubHeader}>{subHeader}</h5>
      <Row className="justify-content-center align-items-start">
        <Col lg="4" className={styles.imgAlign}>
          <img src={src} alt="" width="50%" />
        </Col>
        <Col lg="8">{children}</Col>
      </Row>
    </Container>
  );
}
